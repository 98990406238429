/*
* Actions for Backoffice Admin
* */

import {
    ADD_API_KEY,
    ADD_API_KEY_FAILURE,
    ADD_API_KEY_SUCCESS,
    ADD_USER,
    ADD_USER_FAILURE,
    ADD_USER_SUCCESS,
    DELETE_API_KEY,
    DELETE_API_KEY_FAILURE,
    DELETE_API_KEY_SUCCESS,
    DELETE_USER,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    GET_API_KEY,
    GET_API_KEY_FAILURE,
    GET_API_KEY_SUCCESS,
    GET_USERS,
    GET_USERS_FAILURE,
    GET_USERS_SUCCESS
} from "./types";


export const getUser = (payload) => ({
    type: GET_USERS,
    payload,
});

export const getUserSuccess = (payload) => ({
    type: GET_USERS_SUCCESS,
    payload,
});

export const getUserFailure = (payload) => ({
    type: GET_USERS_FAILURE,
    payload,
});


export const addUser = (payload) => ({
    type: ADD_USER,
    payload,
});

export const addUserSuccess = (payload) => ({
    type: ADD_USER_SUCCESS,
    payload,
});

export const addUserFailure = (payload) => ({
    type: ADD_USER_FAILURE,
    payload,
});


export const deleteUser = (payload) => ({
    type: DELETE_USER,
    payload,
});

export const deleteUserSuccess = (payload) => ({
    type: DELETE_USER_SUCCESS,
    payload,
});

export const deleteUserFailure = (payload) => ({
    type: DELETE_USER_FAILURE,
    payload,
});


export const getAPI = (payload) => ({
    type: GET_API_KEY,
    payload,
});

export const getApiSuccess = (payload) => ({
    type: GET_API_KEY_SUCCESS,
    payload,
});

export const getApiFailure = (payload) => ({
    type: GET_API_KEY_FAILURE,
    payload,
});

export const addApiKey = (payload) => ({
    type: ADD_API_KEY,
    payload,
});

export const addApiKeySuccess = (payload) => ({
    type: ADD_API_KEY_SUCCESS,
    payload,
});

export const addApiKeyFailure = (payload) => ({
    type: ADD_API_KEY_FAILURE,
    payload,
});

export const deleteApiKey = (payload) => ({
    type: DELETE_API_KEY,
    payload,
});

export const deleteApiKeySuccess = (payload) => ({
    type: DELETE_API_KEY_SUCCESS,
    payload,
});

export const deleteApiKeyFailure = (payload) => ({
    type: DELETE_API_KEY_FAILURE,
    payload,
});
