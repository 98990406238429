/*
* Action For sign up
* */

import {REGISTER_REQUEST, REGISTER_REQUEST_FAILURE, REGISTER_REQUEST_SUCCESS,} from './types';

export const signupRequest = (payload) => ({
    type: REGISTER_REQUEST,
    payload,
});

export const signupRequestSuccess = (payload) => ({
    type: REGISTER_REQUEST_SUCCESS,
    payload,
});

export const signupRequestFail = (payload) => ({
    type: REGISTER_REQUEST_FAILURE,
    payload,
});
