import axios from "axios";
import {GET_NEWS_LIST} from '../actions/types'
import {newsSuccess, newsFailure} from "../actions";
import {all, put, takeEvery} from 'redux-saga/effects'
import moment from "moment";
import {chunk} from 'lodash'

function* getNewsList(actions) {
  try {
    const res = yield axios.get(`https://cryptocontrol.io/api/v1/public/news/category?key=2b03d49ea5781351086c6d88851f1c3d`, actions.payload);

    const tmp = []

    const {data} = res

    // add category to each new
    for (const dataKey in data) {
      if (data.hasOwnProperty(dataKey)) {
        data[dataKey].map((e) => {
          e.category = dataKey
          tmp.push(e)
          return e
        })
      }
    }

    // sorting as time
    const pre = tmp.sort((a, b) =>
      moment(a.publishedAt) > moment(b.publishedAt) ? -1
        : moment(a.publishedAt) < moment(b.publishedAt)
        ? 1 : 0)

    // slice to lists as 7 item per list
    const allList = chunk(pre, 7)
    const newsList = allList[0]

    const payload = {
      allList,
      newsList
    }

    yield put(newsSuccess(payload));

  } catch (e) {
    yield put(newsFailure(e));
  }
}

export function* watchGetNews() {
  yield takeEvery(GET_NEWS_LIST, getNewsList)
}

export default function* rootSaga() {
  yield all([
    watchGetNews()
  ])
}
