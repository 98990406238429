/**
 * Create By Sanjay
 * Action Types Of Redux
 */

export const SEARCH_COIN_DETAILS_CRYSTAL = 'SEARCH_COIN_DETAILS_CRYSTAL';
export const SEARCH_COIN_DETAILS_CRYSTAL_SUCCESSFUL = 'SEARCH_COIN_DETAILS_CRYSTAL_SUCCESSFUL';
export const SEARCH_COIN_DETAILS_CRYSTAL_FAILURE = 'SEARCH_COIN_DETAILS_CRYSTAL_FAILURE';

export const SEARCH_COIN_DETAILS_BIG = 'SEARCH_COIN_DETAILS_BIG';
export const SEARCH_COIN_DETAILS_BIG_SUCCESSFUL = 'SEARCH_COIN_DETAILS_BIG_SUCCESSFUL';
export const SEARCH_COIN_DETAILS_BIG_FAILURE = 'SEARCH_COIN_DETAILS_BIG_FAILURE';

export const SEARCH_COIN_DETAILS_SCORECHAIN = 'SEARCH_COIN_DETAILS_SCORECHAIN';
export const SEARCH_COIN_DETAILS_SCORECHAIN_SUCCESSFUL = 'SEARCH_COIN_DETAILS_SCORECHAIN_SUCCESSFUL';
export const SEARCH_COIN_DETAILS_SCORECHAIN_FAILURE = 'SEARCH_COIN_DETAILS_SCORECHAIN_FAILURE';

export const SEARCH_COIN_DETAILS_COINFIRM = 'SEARCH_COIN_DETAILS_COINFIRM';
export const SEARCH_COIN_DETAILS_COINFIRM_SUCCESSFUL = 'SEARCH_COIN_DETAILS_COINFIRM_SUCCESSFUL';
export const SEARCH_COIN_DETAILS_COINFIRM_FAILURE = 'SEARCH_COIN_DETAILS_COINFIRM_FAILURE';

export const SEARCH_COIN_DETAILS_CIPHER = 'SEARCH_COIN_DETAILS_CIPHER';
export const SEARCH_COIN_DETAILS_CIPHER_SUCCESSFUL = 'SEARCH_COIN_DETAILS_CIPHER_SUCCESSFUL';
export const SEARCH_COIN_DETAILS_CIPHER_FAILURE = 'SEARCH_COIN_DETAILS_CIPHER_FAILURE';

export const SEARCH_COIN_DETAILS_ELLIPTIC = 'SEARCH_COIN_DETAILS_ELLIPTIC';
export const SEARCH_COIN_DETAILS_ELLIPTIC_SUCCESSFUL = 'SEARCH_COIN_DETAILS_ELLIPTIC_SUCCESSFUL';
export const SEARCH_COIN_DETAILS_ELLIPTIC_FAILURE = 'SEARCH_COIN_DETAILS_ELLIPTIC_FAILURE';

export const SEARCH_COIN_DETAILS_CHAINALYSIS = 'SEARCH_COIN_DETAILS_CHAINALYSIS';
export const SEARCH_COIN_DETAILS_CHAINALYSIS_SUCCESSFUL = 'SEARCH_COIN_DETAILS_CHAINALYSIS_SUCCESSFUL';
export const SEARCH_COIN_DETAILS_CHAINALYSIS_FAILURE = 'SEARCH_COIN_DETAILS_CHAINALYSIS_FAILURE';

export const SEARCH_COIN_DETAILS_EY = 'SEARCH_COIN_DETAILS_EY';
export const SEARCH_COIN_DETAILS_EY_SUCCESSFUL = 'SEARCH_COIN_DETAILS_EY_SUCCESSFUL';
export const SEARCH_COIN_DETAILS_EY_FAILURE = 'SEARCH_COIN_DETAILS_EY_FAILURE';

export const SEARCH_COIN_DETAILS_DMG = 'SEARCH_COIN_DETAILS_DMG';
export const SEARCH_COIN_DETAILS_DMG_SUCCESSFUL = 'SEARCH_COIN_DETAILS_DMG_SUCCESSFUL';
export const SEARCH_COIN_DETAILS_DMG_FAILURE = 'SEARCH_COIN_DETAILS_DMG_FAILURE';

export const SEARCH_COIN_DETAILS_MERKLE = 'SEARCH_COIN_DETAILS_DMG';
export const SEARCH_COIN_DETAILS_MERKLE_SUCCESSFUL = 'SEARCH_COIN_DETAILS_MERKLE_SUCCESSFUL';
export const SEARCH_COIN_DETAILS_MERKLE_FAILURE = 'SEARCH_COIN_DETAILS_MERKLE_FAILURE';

export const GET_SELECTED_COIN = 'GET_SELECTED_COIN';
export const SAVE_SELECTED_COIN_TYPE_ID = 'SAVE_SELECTED_COIN_TYPE_ID'
export const GET_API_MODE = 'GET_API_MODE';

export const GET_BALANCE = 'GET_BALANCE';
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS';
export const GET_BALANCE_FAILURE = 'GET_BALANCE_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_REQUEST_SUCCESS = 'REGISTER_REQUEST_SUCCESS';
export const REGISTER_REQUEST_FAILURE = 'REGISTER_REQUEST_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';

export const GET_SUPPORTED_COIN_PROVIDERS = 'GET_SUPPORTED_COIN_PROVIDERS';
export const GET_SUPPORTED_COIN_PROVIDERS_SUCCESS = 'GET_SUPPORTED_COIN_PROVIDERS_SUCCESS';
export const GET_SUPPORTED_COIN_PROVIDERS_FAILURE = 'GET_SUPPORTED_COIN_PROVIDERS_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_REQUEST_SUCCESS = 'FORGOT_PASSWORD_REQUEST_SUCCESS';
export const FORGOT_PASSWORD_REQUEST_FAILURE = 'FORGOT_PASSWORD_REQUEST_FAILURE';

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILURE = "GET_TRANSACTIONS_FAILURE";

export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAILURE = "GET_ADDRESS_FAILURE";

export const GET_BALANCE_GRAPH = "GET_BALANCE_GRAPH";
export const GET_BALANCE_GRAPH_SUCCESS = "GET_BALANCE_GRAPH_SUCCESS";
export const GET_BALANCE_GRAPH_FAILURE = "GET_BALANCE_GRAPH_FAILURE";

export const GET_WEB_ANALYSIS = "GET_WEB_ANALYSIS";
export const GET_WEB_ANALYSIS_SUCCESS = "GET_WEB_ANALYSIS_SUCCESS";
export const GET_WEB_ANALYSIS_FAILURE = "GET_WEB_ANALYSIS_FAILURE";

export const GET_MARKET_CAP_DATA = "GET_MARKET_CAP_DATA";
export const GET_MARKET_CAP_DATA_SUCCESS = "GET_MARKET_CAP_DATA_SUCCESS";
export const GET_MARKET_CAP_DATA_FAILURE = "GET_MARKET_CAP_DATA_FAILURE";

export const GET_TRANSACTIONS_ADDRESS = "GET_TRANSACTIONS_ADDRESS";
export const GET_TRANSACTIONS_ADDRESS_SUCCESS = "GET_TRANSACTIONS_ADDRESS_SUCCESS";
export const GET_TRANSACTIONS_ADDRESS_FAILURE = "GET_TRANSACTIONS_ADDRESS_FAILURE";

export const SEARCH_COIN_ADDRESS = 'SEARCH_COIN_ADDRESS';
export const SEARCH_COIN_ADDRESS_SUCCESS = 'SEARCH_COIN_ADDRESS_SUCCESS';
export const SEARCH_COIN_ADDRESS_FAILURE = 'SEARCH_COIN_ADDRESS_FAILURE';

export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const GET_COUNT_SUPER_ADMIN = "GET_COUNT_SUPER_ADMIN";
export const GET_COUNT_SUPER_ADMIN_SUCCESS = "GET_COUNT_SUPER_ADMIN_SUCCESS";
export const GET_COUNT_SUPER_ADMIN_FAILURE = "GET_COUNT_SUPER_ADMIN_FAILURE";

export const GET_COMPANY_LIST = "GET_COMPANY_LIST";
export const GET_COMPANY_LIST_SUCCESS = "GET_COMPANY_LIST_SUCCESS";
export const GET_COMPANY_LIST_FAILURE = "GET_COMPANY_LIST_FAILURE";

export const GET_COMPANY_PROVIDER_LOG = "GET_COMPANY_PROVIDER_LOG";
export const GET_COMPANY_PROVIDER_LOG_SUCCESS = "GET_COMPANY_PROVIDER_LOG_SUCCESS";
export const GET_COMPANY_PROVIDER_LOG_FAILURE = "GET_COMPANY_PROVIDER_LOG_FAILURE";

export const GET_PROVIDER_INNER_LOG = "GET_PROVIDER_INNER_LOG";
export const GET_PROVIDER_INNER_LOG_SUCCESS = "GET_PROVIDER_INNER_LOG_SUCCESS";
export const GET_PROVIDER_INNER_LOG_FAILURE = "GET_PROVIDER_INNER_LOG_FAILURE";

export const ADD_COMPANY = "ADD_COMPANY";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";

export const DELETE_COMPANY = "DELETE_COMPANY";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAILURE = "DELETE_COMPANY_FAILURE";

export const ADD_CREDIT = "ADD_CREDIT";
export const ADD_CREDIT_SUCCESS = "ADD_CREDIT_SUCCESS";
export const ADD_CREDIT_FAILURE = "ADD_CREDIT_FAILURE";


export const RESET_STORE = "RESET_STORE";

export const GET_MONITORING_LIST = "GET_MONITORING_LIST";
export const GET_MONITORING_LIST_SUCCESS = "GET_MONITORING_LIST_SUCCESS";
export const GET_MONITORING_LIST_FAILURE = "GET_MONITORING_LIST_FAILURE";

export const SET_NOTIFICATION_ALERT = "SET_NOTIFICATION_ALERT";

export const DELETE_MONITORING_RECORD = "DELETE_MONITORING_RECORD";

export const ADD_EDIT_MONITORING_USER = "ADD_EDIT_MONITORING_USER";
export const ADD_EDIT_MONITORING_USER_SUCCESS = "ADD_EDIT_MONITORING_USER_SUCCESS";
export const ADD_EDIT_MONITORING_USER_FAILURE = "ADD_EDIT_MONITORING_USER_FAILURE";

export const GET_MONITORING_BY_ID = "GET_MONITORING_BY_ID";
export const GET_MONITORING_BY_ID_SUCCESS = "GET_MONITORING_BY_ID_SUCCESS";
export const GET_MONITORING_BY_ID_FAILURE = "GET_MONITORING_BY_ID_FAILURE";

export const ADD_MONITORING = "ADD_MONITORING";

export const DELETE_MONITOR_ADDRESS = "DELETE_MONITOR_ADDRESS";

export const ADD_MONITORING_ADDRESS = "ADD_MONITORING_ADDRESS";

export const ADD_MONITORING_ALERT = "ADD_MONITORING_ALERT";

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const REMOVE_SCORE_FROM_ADDRESS = "REMOVE_SCORE_FROM_ADDRESS";

export const GET_SCORE_FOR_MONITORING_ADDRESS = "GET_SCORE_FOR_MONITORING_ADDRESS";
export const GET_SCORE_FOR_MONITORING_ADDRESS_SUCCESS = "GET_SCORE_FOR_MONITORING_ADDRESS_SUCCESS";
export const GET_SCORE_FOR_MONITORING_ADDRESS_FAILURE = "GET_SCORE_FOR_MONITORING_ADDRESS_FAILURE";

export const SENT_SCORE_ADDRESS_PROVIDER = "SENT_SCORE_ADDRESS_PROVIDER";

export const GET_COUNT_ADMIN = "GET_COUNT_ADMIN";
export const GET_COUNT_ADMIN_SUCCESS = "GET_COUNT_ADMIN_SUCCESS";
export const GET_COUNT_ADMIN_FAILURE = "GET_COUNT_ADMIN_FAILURE";

export const GET_API_KEY = "GET_API_KEY";
export const GET_API_KEY_SUCCESS = "GET_API_KEY_SUCCESS";
export const GET_API_KEY_FAILURE = "GET_API_KEY_FAILURE";

export const COMMENT_ADD_UPDATE_MONITORING = "COMMENT_ADD_UPDATE_MONITORING";

export const ADD_API_KEY = "ADD_API_KEY";
export const ADD_API_KEY_SUCCESS = "ADD_API_KEY_SUCCESS";
export const ADD_API_KEY_FAILURE = "ADD_API_KEY_FAILURE";

export const DELETE_API_KEY = "DELETE_API_KEY";
export const DELETE_API_KEY_SUCCESS = "DELETE_API_KEY_SUCCESS";
export const DELETE_API_KEY_FAILURE = "DELETE_API_KEY_FAILURE";

export const COMMENT_LIST_MONITORING = "COMMENT_LIST_MONITORING";
export const COMMENT_LIST_MONITORING_SUCCESS = "COMMENT_LIST_MONITORING_SUCCESS";
export const COMMENT_LIST_MONITORING_FAILURE = "COMMENT_LIST_MONITORING_FAILURE";

export const DELETE_COMMENT_MONITORING = "DELETE_COMMENT_MONITORING";

export const REGISTER_HASH = "REGISTER_HASH";
export const REGISTER_HASH_SUCCESS = "REGISTER_HASH_SUCCESS";
export const REGISTER_HASH_FAILURE = "REGISTER_HASH_FAILURE"

export const GET_CREDIT = "GET_CREDIT";
export const GET_CREDIT_SUCCESS = "GET_CREDIT_SUCCESS";
export const GET_CREDIT_FAILURE = "GET_CREDIT_FAILURE";

export const SET_RULES = "SET_RULES";

export const GET_RULES_MYUNIAML = "GET_RULES_MYUNIAML";
export const GET_RULES_MYUNIAML_SUCCESS = "GET_RULES_MYUNIAML_SUCCESS";
export const GET_RULES_MYUNIAML_FAILURE = "GET_RULES_MYUNIAML_FAILURE";

export const GET_SCORING_HISTORY = "GET_SCORING_HISTORY";
export const GET_SCORING_HISTORY_SUCCESS = "GET_SCORING_HISTORY_SUCCESS";
export const GET_SCORING_HISTORY_FAILURE = "GET_SCORING_HISTORY_FAILURE";

export const INDIVIDUAL_USER_LIST = "INDIVIDUAL_USER_LIST";
export const INDIVIDUAL_USER_LIST_SUCCESS = "INDIVIDUAL_USER_LIST_SUCCESS";
export const INDIVIDUAL_USER_LIST_FAILURE = "INDIVIDUAL_USER_LIST_FAILURE";

export const ADD_CREDIT_INDIVIDUAL = "ADD_CREDIT_INDIVIDUAL";
export const ADD_CREDIT_INDIVIDUAL_SUCCESS = "ADD_CREDIT_INDIVIDUAL_SUCCESS";
export const ADD_CREDIT_INDIVIDUAL_FAILURE = "ADD_CREDIT_INDIVIDUAL_FAILURE";


export const DELETE_INDIVIDUAL = "DELETE_INDIVIDUAL";
export const DELETE_INDIVIDUAL_SUCCESS = "DELETE_INDIVIDUAL_SUCCESS";
export const DELETE_INDIVIDUAL_FAILURE = "DELETE_INDIVIDUAL_FAILURE";

export const GET_INDIVIDUAL_PROVIDER_LOG = "GET_INDIVIDUAL_PROVIDER_LOG";
export const GET_INDIVIDUAL_PROVIDER_LOG_SUCCESS = "GET_INDIVIDUAL_PROVIDER_LOG_SUCCESS";
export const GET_INDIVIDUAL_PROVIDER_LOG_FAILURE = "GET_INDIVIDUAL_PROVIDER_LOG_FAILURE";

export const GET_INDIVIDUAL_PROVIDER_INNER_LOG = "GET_INDIVIDUAL_PROVIDER_INNER_LOG";
export const GET_INDIVIDUAL_PROVIDER_INNER_LOG_SUCCESS = "GET_INDIVIDUAL_PROVIDER_INNER_LOG_SUCCESS";
export const GET_INDIVIDUAL_PROVIDER_INNER_LOG_FAILURE = "GET_INDIVIDUAL_PROVIDER_INNER_LOG_FAILURE";

export const CHANGE_TEMPLATE_ID = "CHANGE_TEMPLATE_ID";
export const CHANGE_TEMPLATE_ID_SUCCESS = "CHANGE_TEMPLATE_ID_SUCCESS";
export const CHANGE_TEMPLATE_ID_FAILURE = "CHANGE_TEMPLATE_ID_FAILURE";

export const GET_TEMPLATE_ID = "GET_TEMPLATE_ID";
export const GET_TEMPLATE_ID_SUCCESS = "GET_TEMPLATE_ID_SUCCESS";
export const GET_TEMPLATE_ID_FAILURE = "GET_TEMPLATE_ID_FAILURE";


export const ADD_API_KEY_SUPERADMIN = "ADD_API_KEY_SUPERADMIN";
export const ADD_API_KEY_SUPERADMIN_SUCCESS = "ADD_API_KEY_SUPERADMIN_SUCCESS";
export const ADD_API_KEY_SUPERADMIN_FAILURE = "ADD_API_KEY_SUPERADMIN_FAILURE";

export const GET_API_KEY_SUPERADMIN = "GET_API_KEY_SUPERADMIN";
export const GET_API_KEY_SUPERADMIN_SUCCESS = "GET_API_KEY_SUPERADMIN_SUCCESS";
export const GET_API_KEY_SUPERADMIN_FAILURE = "GET_API_KEY_SUPERADMIN_FAILURE";

export const GET_COMPANY_API_KEY = "GET_COMPANY_API_KEY";
export const GET_COMPANY_API_KEY_SUCCESS = "GET_COMPANY_API_KEY_SUCCESS";
export const GET_COMPANY_API_KEY_FAILURE = "GET_COMPANY_API_KEY_FAILURE";

export const ADD_COMPANY_API_KEY = "ADD_COMPANY_API_KEY";
export const ADD_COMPANY_API_KEY_SUCCESS = "ADD_COMPANY_API_KEY_SUCCESS";
export const ADD_COMPANY_API_KEY_FAILURE = "ADD_COMPANY_API_KEY_FAILURE";

export const ADD_INDIVIDUAL_LIMIT = "ADD_INDIVIDUAL_LIMIT";
export const ADD_INDIVIDUAL_LIMIT_SUCCESS = "ADD_INDIVIDUAL_LIMIT_SUCCESS";
export const ADD_INDIVIDUAL_LIMIT_FAILURE = "ADD_INDIVIDUAL_LIMIT_FAILURE";

export const GET_TOGGLE_STATUS = 'GET_TOGGLE_STATUS';

export const GET_NEWS_LIST = 'GET_NEWS_LIST';
export const GET_NEWS_LIST_SUCCESS = 'GET_NEW_LIST_SUCCESS';
export const GET_NEWS_LIST_FAILURE = 'GET_NEW_LIST_FAILURE';

export const GET_MORE_NEWS = 'GET_MORE_NEWS';

export const GET_CRYPTO_MARKET = 'GET_CRYPTO_MARKET';
export const GET_CRYPTO_MARKET_SUCCESS = 'GET_CRYPTO_MARKET_SUCCESS';
export const GET_CRYPTO_MARKET_FAILURE = 'GET_CRYPTO_MARKET_FAILURE';

export const CHANGE_CRYPTO_MARKET_INDEX = 'CHANGE_CRYPTO_MARKET_INDEX';

export const GET_EXCHANGE_RATE = 'GET_EXCHANGE_RATE'
export const GET_EXCHANGE_RATE_SUCCESS = 'GET_EXCHANGE_RATE_SUCCESS'
export const GET_EXCHANGE_RATE_FAILURE = 'GET_EXCHANGE_RATE_FAILURE'
