/* Private Route Component */
import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {getToken} from '../config/helper';

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={(props) => (getToken() ? (
            <Component {...props} />
        ) : (
            <Redirect to="/signin"/>
        ))}
    />
);

export default PrivateRoute;
