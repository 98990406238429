/*
* Super Admin Reducer
* */

import {
    GET_COUNT_SUPER_ADMIN,
    GET_COUNT_SUPER_ADMIN_FAILURE,
    GET_COUNT_SUPER_ADMIN_SUCCESS
} from '../actions/types';

/* initial state */
const INITIAL_STATE = {
    dataCount: null,
    loadingCount: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_COUNT_SUPER_ADMIN:
            return {...state, loadingCount: true};
        case GET_COUNT_SUPER_ADMIN_SUCCESS:
            return {...state, dataCount: action.payload, loadingCount: false};
        case GET_COUNT_SUPER_ADMIN_FAILURE:
            return {...state, dataCount: action.payload, loadingCount: false};
        default:
            return state;
    }
};
