import { lazy } from 'react'
const SearchCoin = lazy(() => import('../container/searchCoinv2'))
const Api = lazy(() => import('../container/api'))
const Wallet = lazy(() => import('../container/wallet'))
const CoinDetails = lazy(() => import('../container/coinDetails'))
const PageNoteFound = lazy(() => import('../container/pageNotFound'))
const Monitoring = lazy(() => import('../container/monitoring'))
const MyUniaml = lazy(() => import('../container/myUNIAML'))
const EditMonitoring = lazy(() => import('../components/monitoringComponents/EditMonitoringComponents'))
const SignUp = lazy(() => import('../container/signUp'))
const SignIn = lazy(() => import('../container/signIn'))
const ForgotPassword = lazy(() => import('../container/forgot-password'))
const BackOffice = lazy(() => import('../container/backOffice'))
const emailVerified = lazy(() => import('../container/emailVerified'))
const BackofficeSuperAdmin = lazy(() => import('../container/backOfficeSuperAdmin'))
const ResetPassword = lazy(() => import('../container/reset-password'))
const Thankyou = lazy(() => import('../container/thankyou'))
const AccountVerify = lazy(() => import('../container/accountVerify'))
const ScoringHistory = lazy(() => import('../container/scoringHistory'))
const OrderHistory = lazy(() => import('../container/orderHistory'))
const HomePage = lazy(() => import('../container/homePage'))

export const RoutesPath = [
	{
		path: '/(|home)',
		component: HomePage,
		private: false,
	},
	{
		path: '/coinDetails',
		component: CoinDetails,
		private: true,
	},
	{
		path: '/api',
		component: Api,
		private: false,
	},
	{
		path: '/wallet',
		component: Wallet,
		private: false,
	},
	{
		path: '/monitoring',
		component: Monitoring,
		private: true,
	},
	{
		path: '/myuniaml',
		component: MyUniaml,
		private: true,
	},
	{
		path: '/monitoring/:monitoringId',
		component: EditMonitoring,
		private: true,
	},
	{
		path: '/signup',
		component: SignUp,
		private: false,
	},
	{
		path: '/signin',
		component: SignIn,
		private: false,
	},
	{
		path: '/forgot-password',
		component: ForgotPassword,
		private: false,
	},
	{
		path: '/backoffice',
		component: BackOffice,
		private: true,
	},
	{
		path: '/emailverified',
		component: emailVerified,
		private: false,
	},
	{
		path: '/backoffice-super-admin',
		component: BackofficeSuperAdmin,
		private: true,
	},
	{
		path: '/reset-password',
		component: ResetPassword,
		private: false,
	},
	{
		path: '/thankyou',
		component: Thankyou,
		private: false,
	},
	{
		path: '/accountVerify',
		component: AccountVerify,
		private: false,
	},
	{
		path: '/scoring-history',
		component: ScoringHistory,
		private: true,
	},
	{
		path: '/order-history',
		component: OrderHistory,
		private: true,
	},
	{
		path: '/searchCoin',
		component: SearchCoin,
		private: true,
	},
]
