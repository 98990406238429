import axios from 'axios';
import { apiUrl } from './helper';

export const verifyEmail = (token) => axios.get(`${apiUrl}/api/verify-email?token=${token}`).then((value) => value);

export const resetPassword = (token, password) => axios.post(`${apiUrl}/api/reset-password`, { password, token }).then((value) => value);

export const addPassword = (token, password) => axios.post(`${apiUrl}/api/add-password`, { password, token }).then((value) => value);


