import moment from "moment";
import scorechain from "../assets/img/searchCoin/scorechain.svg";
import blockchain_group from "../assets/img/coinDetails/big-second.svg";
import cipher_trace from "../assets/img/coinDetails/cipher-second.svg";
import chainalysis from "../assets/img/coinDetails/chainalysis.svg";
import crystal from "../assets/img/coinDetails/crystal-white.svg";
import merkle from "../assets/img/searchCoin/merklw-science.png";
import ey from "../assets/img/searchCoin/ey.svg";
import dmg from "../assets/img/coinDetails/DMGBlack.png";

export const walletUrl = 'https://wallet.amlsynapse.com'
export const apiUrl = 'https://apiv2.amlsynapse.com'
export const satoshiToBTC = (value) => value * 0.00000001;

export const weiToEther = (value) => value / 1000000000000000000;

export const setToken = (token) => {
  localStorage.setItem("token", token);
};

export const getToken = () => localStorage.getItem("token");

export const setUserType = (type) => {
  localStorage.setItem("type", type);
};

export const getUserType = () => localStorage.getItem("type");

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const convertTimestamp = (timestamp) => {
  return moment(timestamp * 1000).format("MMM YY");
};

export const convertTimestampToDate = (timestamp) => {
  return moment(timestamp * 1000).format("MMM DD");
};

export const convertTimestampToMonth = (timestamp) => {
  return moment(timestamp * 1000).format("MMM YYYY");
};

export const convertTimestampToYear = (timestamp) => {
  return moment(timestamp * 1000).format("YYYY");
};

export const isSameDay = (timestamp, compare) =>
  moment(timestamp * 1000).isSame(moment(compare * 1000), "day");

export const isSameMonth = (timestamp, compare) =>
  moment(timestamp * 1000).isSame(moment(compare * 1000), "month");

export const isSameYear = (timestamp, compare) =>
  moment(timestamp * 1000).isSame(moment(compare * 1000), "year");

export const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);

export const setUserIp = (ip) => {
  localStorage.setItem("ip", ip);
};

export const getUserIP = () => localStorage.getItem("ip");

export const updateScore = {
  "Every Day": 0,
  "Every Week": 1,
  "Every Month": 2,
  "No Update": 3,
};

export const ScoreFromAPI = {
  0: "Every Day",
  1: "Every Week",
  2: "Every Month",
  3: "No Update",
};

export const perFormatter = (num, digits, currency) => {
  let currencyFormatter = require("currency-formatter");
  try {
    let si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    let value = (num / si[i].value).toFixed(digits).replace(rx, "$1");
    return num === null || num === 0
      ? "N/A"
      : value === "0" || value === "-0"
      ? currencyFormatter.format(num / si[i].value, { code: currency })
      : currencyFormatter.format(value, { code: currency }) + si[i].symbol;
  } catch (e) {
    return currencyFormatter.format(num, { code: currency });
  }
};

export const coinLogoById = (coinId) => {
  return (
    "https://s2.coinmarketcap.com/static/img/coins/64x64/" +
    coinId +
    ".png?_=5bcdbc6"
  );
};

export const providerLogoNew = {
  scorechain,
  blockchain_group,
  cipher_trace,
  chainalysis,
  crystal,
  ey,
  dmg,
  merkle,
};
