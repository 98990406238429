import React from 'react';
import Loader from 'react-loader-spinner';

const LoaderComponent = () => (
  <>
      <div className="overlay">
      </div>
      <div className="overlay__inner">
          <div className="overlay__content">
              <Loader
                  type="ThreeDots"
                  color="#fd9620"
                  height={150}
                  width={150}
              />
          </div>
      </div>
  </>


);

export default LoaderComponent;
