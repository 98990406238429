import {GET_NEWS_LIST, GET_NEWS_LIST_SUCCESS, GET_NEWS_LIST_FAILURE, GET_MORE_NEWS} from "../actions/types";

const INIT_STATUS = {
  newsList: [],
  fetching: false,
  counter: 0,
  allList: []
}

export default (state = INIT_STATUS, action) => {
  switch (action.type) {
    case GET_NEWS_LIST:
      return {...state, fetching: true}
    case GET_NEWS_LIST_SUCCESS:
      return {
        ...state,
        newsList: action.payload.newsList,
        allList: action.payload.allList,
        fetching: false
      }
    case GET_NEWS_LIST_FAILURE:
      return {
        ...state,
        newsList: action.payload,
        fetching: false
      }
    case GET_MORE_NEWS:
      return {
        ...state,
        newsList: state.newsList.concat(state.allList[action.payload]),
        counter: action.payload
      }
    default:
      return state;
  }
}
