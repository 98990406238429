/**
 * This is a Main Component of Application
 */
import React from 'react';
import axios from "axios";
import Routes from './Routes';
import './libCss';
import { setUserIp } from "./config";

axios.get('https://cors-anywhere.herokuapp.com/https://api.ipify.org').then(res => {
    setUserIp(res.data)
});

const App = () => {
    return (
        <div className="app">
            <Routes/>
        </div>
    )
};

export default App;
