/*
* Saga File For Super Admin
* */

import {all, put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';
import {
    GET_COMPANY_LIST,
    GET_COMPANY_PROVIDER_LOG,
    GET_PROVIDER_INNER_LOG,
    ADD_COMPANY,
    DELETE_COMPANY,
    ADD_CREDIT,
    INDIVIDUAL_USER_LIST,
    ADD_CREDIT_INDIVIDUAL,
    DELETE_INDIVIDUAL,
    GET_INDIVIDUAL_PROVIDER_LOG,
    GET_INDIVIDUAL_PROVIDER_INNER_LOG,
    GET_TEMPLATE_ID,
    CHANGE_TEMPLATE_ID,
    GET_API_KEY_SUPERADMIN,
    ADD_API_KEY_SUPERADMIN,
    GET_COMPANY_API_KEY,
    ADD_COMPANY_API_KEY,
    ADD_INDIVIDUAL_LIMIT
} from '../actions/types';
import {
    getCompanyListSuccess,
    getCompanyListFail,
    getCompanyProviderLogSuccess,
    getCompanyProviderLogFail,
    getProviderInnerLogSuccess,
    getProviderInnerLogFail,
    addCompanyFailure,
    addCompanySuccess,
    deleteCompanyFailure,
    deleteCompanySuccess,
    addCreditFailure,
    addCreditSuccess,
    getCompanyList,
    getIndividualUserSuccess,
    getIndividualUserFail,
    deleteIndividualFailure,
    deleteIndividualSuccess,
    getIndividualUser,
    addCreditIndividualFailure,
    addCreditIndividualSuccess,
    getIndividualLogSuccess,
    getIndividualLogFailure,
    getIndividualInnerLogFailure,
    getIndividualInnerLogSuccess,
    setTemplateIDSuccess,
    setTemplateIDFailure,
    getTemplateIDsSuccess,
    getTemplateIDsFailure,
    getTemplateIDs,
    getApiKeysSuccess,
    getApiKeysFailure,
    addApiKeysSuccess,
    addApiKeysFailure,
    getApiKeys,
    getCompanyApiKeys,
    getCompanyApiKeysSuccess,
    getCompanyApiKeysFailure,
    addCompanyApiKeysFailure,
    addCompanyApiKeysSuccess,
    addIndividualLimitSuccess,
    addIndividualLimitFailure

} from '../actions';
import {apiUrl} from "../config";
import {toast} from 'react-toastify';

let cancel;

function* getCompanyListRequest() {
    try {
        const res = yield axios.get(`${apiUrl}/api/super-admin/company-list`);
        yield put(getCompanyListSuccess(res.data.data.companies));
    } catch (e) {
        yield put(getCompanyListFail(e));
    }
}

function* getCompanyProviderLogRequest(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/api/provider-logs/${actions.payload}`);
        yield put(getCompanyProviderLogSuccess(res.data.data));
    } catch (e) {
        yield put(getCompanyProviderLogFail(e));
    }
}

function* getProviderInnerLogRequest(actions) {
    const url = actions.payload.page !== null ? `${apiUrl}/api/logs?page=${actions.payload.page}` : `${apiUrl}/api/logs`;
    try {
        const res = yield axios.post(url, actions.payload.data);
        yield put(getProviderInnerLogSuccess(res.data))
    } catch (e) {
        yield put(getProviderInnerLogFail(e));
    }
}

function* addCompanyRequest(actions) {
    try {
        const res = yield axios.post(`${apiUrl}/api/super-admin/add-company`, actions.payload);
        yield put(addCompanySuccess(res));
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
        if (res.data.meta.status === 1) toast.success(res.data.meta.message)
    } catch (e) {
        yield put(addCompanyFailure(e));
    }
}

function* deleteCompanyRequest(actions) {
    try {
        const res = yield axios.post(`${apiUrl}/api/super-admin/delete-company`, actions.payload);
        yield put(deleteCompanySuccess(res));
        yield put(getCompanyList());
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
        if (res.data.meta.status === 1) toast.success(res.data.meta.message)
    } catch (e) {
        yield put(deleteCompanyFailure(e));
    }
}

function* addCreditRequest(actions) {
    const {credit} = actions.payload;
    try {
        const res = yield axios.post(`${apiUrl}/api/super-admin/add-credits/`, {
            company_id: credit.company_id,
            provider: credit.providers,
            company_name: credit.company_name,
            limit:credit.limit
        });
        yield put(addCreditSuccess(res));
        yield put(getCompanyList());
    } catch (e) {
        yield put(addCreditFailure(e));
    }
}

function* getIndividualUserListAPI(actions) {
    const url = actions.payload !== undefined ? `${apiUrl}/api/super-admin/individual-users?page=${actions.payload.page}&search=${actions.payload.search}` : `${apiUrl}/api/super-admin/individual-users`;
    cancel && cancel();
    try {
        const res = yield axios.get(url, {
            cancelToken: new axios.CancelToken((c) => {
                cancel = c
            })
        });
        yield put(getIndividualUserSuccess(res.data))
    } catch (e) {
        yield put(getIndividualUserFail(e));
    }
}

function* deleteIndividualRequest(actions) {
    try {
        const res = yield axios.post(`${apiUrl}/api/super-admin/individual-user-delete`, actions.payload);
        yield put(deleteIndividualSuccess(res));
        yield put(getIndividualUser());
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
        if (res.data.meta.status === 1) toast.success(res.data.meta.message)
    } catch (e) {
        yield put(deleteIndividualFailure(e));
    }
}

function* addCreditsIndividual(actions) {
    const {credit} = actions.payload;

    try {
        const res = yield axios.post(`${apiUrl}/api/super-admin/individual-user-add-credits`, {
            user_id: credit.user_id,
            credits: credit.credits,
            name: credit.name
        });
        yield put(addCreditIndividualSuccess(res));
        yield put(getIndividualUser());
    } catch (e) {
        yield put(addCreditIndividualFailure(e));
    }
}

function* getIndividualProviderLogRequest(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/api/super-admin/individual-provider-logs/${actions.payload}`);
        yield put(getIndividualLogSuccess(res.data.data));
    } catch (e) {
        yield put(getIndividualLogFailure(e));
    }
}

function* getIndividualInnerProviderLogRequest(actions) {
    const url = actions.payload.page !== null ? `${apiUrl}/api/super-admin/individual-logs?page=${actions.payload.page}` : `${apiUrl}/api/super-admin/individual-logs`;
    try {
        const res = yield axios.post(url, actions.payload.data);
        yield put(getIndividualInnerLogSuccess(res.data,));
    } catch (e) {
        yield put(getIndividualInnerLogFailure(e));
    }
}

function* setTemplateIDRequest(actions) {

    try {
        const res = yield axios.post(`${apiUrl}/api/super-admin/create-template-id`, actions.payload);
        yield put(setTemplateIDSuccess(res.data.data));
        yield put(getTemplateIDs())
    } catch (e) {
        yield put(setTemplateIDFailure(e));
    }
}

function* getTemplateIDList(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/api/get-template-id`);
        yield put(getTemplateIDsSuccess(res.data.data));
    } catch (e) {
        yield put(getTemplateIDsFailure(e));
    }
}

function* addApiKeySuperAdmin(actions) {
    try {
        const res = yield axios.post(`${apiUrl}/api/super-admin/create-api-key`, actions.payload);
        yield put(addApiKeysSuccess(res.data.data));
        yield put(getApiKeys())
    } catch (e) {
        yield put(addApiKeysFailure(e));
    }
}
function* getApiKeySuperAdmin(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/api/super-admin/get-api-key`, actions.payload);
        yield put(getApiKeysSuccess(res.data));

    } catch (e) {
        yield put(getApiKeysFailure(e));
    }
}

function* getApiKeyCompany(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/api/super-admin/company/create-api-key/${actions.payload}`);
        yield put(getCompanyApiKeysSuccess(res.data));
    } catch (e) {
        yield put(getCompanyApiKeysFailure(e));
    }
}

function* addApiKeyCompany(actions) {
const{provider,api_key,company_id,ETH,XRP}=actions.payload;
    try {
        const res = yield axios.post(`${apiUrl}/api/super-admin/company/create-api-key/${company_id}`,{provider,api_key,ETH,XRP});
        yield put(addCompanyApiKeysSuccess(res.data));
        yield put(getCompanyApiKeys(company_id))

    } catch (e) {
        yield put(addCompanyApiKeysFailure(e));
    }
}
function* addLimitIndividual(actions) {
    try {
        const res = yield axios.post(`${apiUrl}/api/super-admin/add-limit`,actions.payload);
        yield put(addIndividualLimitSuccess(res.data));
    } catch (e) {
        yield put(addIndividualLimitFailure(e));
    }
}

/* watch function for Get Company List */
export function* watchCompanyListApi() {
    yield takeEvery(GET_COMPANY_LIST, getCompanyListRequest);
}

/* watch function for Get Company Provider Log */
export function* watchCompanyProviderLogApi() {
    yield takeEvery(GET_COMPANY_PROVIDER_LOG, getCompanyProviderLogRequest);
}

/* watch function for Get Provider Inner Log */
export function* watchProviderInnerLogApi() {
    yield takeEvery(GET_PROVIDER_INNER_LOG, getProviderInnerLogRequest);
}

/* watch function for adding company in user tab */
export function* watchAddCompanyApi() {
    yield takeEvery(ADD_COMPANY, addCompanyRequest);
}

/*Watch function for deleting company in user tab */
export function* watchDeleteCompanyApi() {
    yield takeEvery(DELETE_COMPANY, deleteCompanyRequest);
}

/*Watch function for adding credit in user tab */
export function* watchAddCreditApi() {
    yield takeEvery(ADD_CREDIT, addCreditRequest);
}

/*Watch function for get Individual User List*/
export function* watchGetIndividualUserAPI() {
    yield takeEvery(INDIVIDUAL_USER_LIST, getIndividualUserListAPI)
}

/* watch function for Get Company List */
export function* watchDeleteIndividual() {
    yield takeEvery(DELETE_INDIVIDUAL, deleteIndividualRequest);
}

/* watch function for Get Company List */
export function* watchAddCreditIndividual() {
    yield takeEvery(ADD_CREDIT_INDIVIDUAL, addCreditsIndividual);
}

/* watch function for Get Company List */
export function* watchGetIndividualProviderLog() {
    yield takeEvery(GET_INDIVIDUAL_PROVIDER_LOG, getIndividualProviderLogRequest);
}

/* watch function for Get Company List */
export function* watchGetIndividualInnerProviderLog() {
    yield takeEvery(GET_INDIVIDUAL_PROVIDER_INNER_LOG, getIndividualInnerProviderLogRequest);
}

/* watch function for Get template IDs List */
export function* watchGetTemplateID() {
    yield takeEvery(GET_TEMPLATE_ID, getTemplateIDList);
}

/* watch function for Get Company List */
export function* watchSetTemplateID() {
    yield takeEvery(CHANGE_TEMPLATE_ID, setTemplateIDRequest);
}
/* watch function for Get template IDs List */
export function* watchGetAPIKey() {
    yield takeEvery(GET_API_KEY_SUPERADMIN, getApiKeySuperAdmin);
}

/* watch function for Get Company List */
export function* watchAddApiKey() {
    yield takeEvery(ADD_API_KEY_SUPERADMIN, addApiKeySuperAdmin);
}

/* watch function for Get template IDs List */
export function* watchGetCompanyAPIKey() {
    yield takeEvery(GET_COMPANY_API_KEY, getApiKeyCompany);
}

/* watch function for Get Company List */
export function* watchAddCompanyApiKey() {
    yield takeEvery(ADD_COMPANY_API_KEY, addApiKeyCompany);
}

/* watch function for Get Company List */
export function* watchAddLimit() {
    yield takeEvery(ADD_INDIVIDUAL_LIMIT, addLimitIndividual);
}

export default function* rootSaga() {
    yield all([
        watchCompanyListApi(),
        watchCompanyProviderLogApi(),
        watchProviderInnerLogApi(),
        watchAddCompanyApi(),
        watchDeleteCompanyApi(),
        watchAddCreditApi(),
        watchGetIndividualUserAPI(),
        watchDeleteIndividual(),
        watchAddCreditIndividual(),
        watchGetIndividualProviderLog(),
        watchGetIndividualInnerProviderLog(),
        watchGetTemplateID(),
        watchSetTemplateID(),
        watchGetAPIKey(),
        watchAddApiKey(),
        watchGetCompanyAPIKey(),
        watchAddCompanyApiKey(),
        watchAddLimit()

    ]);
}
