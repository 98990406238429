/*
* Reducer for search coin details
* */

import {
    GET_API_MODE,
    GET_SELECTED_COIN,
    GET_SUPPORTED_COIN_PROVIDERS,
    GET_SUPPORTED_COIN_PROVIDERS_FAILURE,
    GET_SUPPORTED_COIN_PROVIDERS_SUCCESS,
    SAVE_SELECTED_COIN_TYPE_ID,
    SEARCH_COIN_DETAILS_BIG,
    SEARCH_COIN_DETAILS_BIG_FAILURE,
    SEARCH_COIN_DETAILS_BIG_SUCCESSFUL,
    SEARCH_COIN_DETAILS_CHAINALYSIS,
    SEARCH_COIN_DETAILS_CHAINALYSIS_FAILURE,
    SEARCH_COIN_DETAILS_CHAINALYSIS_SUCCESSFUL,
    SEARCH_COIN_DETAILS_CIPHER,
    SEARCH_COIN_DETAILS_CIPHER_FAILURE,
    SEARCH_COIN_DETAILS_CIPHER_SUCCESSFUL,
    SEARCH_COIN_DETAILS_COINFIRM,
    SEARCH_COIN_DETAILS_COINFIRM_FAILURE,
    SEARCH_COIN_DETAILS_COINFIRM_SUCCESSFUL,
    SEARCH_COIN_DETAILS_CRYSTAL,
    SEARCH_COIN_DETAILS_CRYSTAL_FAILURE,
    SEARCH_COIN_DETAILS_CRYSTAL_SUCCESSFUL,
    SEARCH_COIN_DETAILS_ELLIPTIC,
    SEARCH_COIN_DETAILS_ELLIPTIC_FAILURE,
    SEARCH_COIN_DETAILS_ELLIPTIC_SUCCESSFUL,
    SEARCH_COIN_DETAILS_EY,
    SEARCH_COIN_DETAILS_EY_FAILURE,
    SEARCH_COIN_DETAILS_EY_SUCCESSFUL,
    SEARCH_COIN_DETAILS_SCORECHAIN,
    SEARCH_COIN_DETAILS_SCORECHAIN_FAILURE,
    SEARCH_COIN_DETAILS_SCORECHAIN_SUCCESSFUL,
    SEARCH_COIN_DETAILS_DMG,
    SEARCH_COIN_DETAILS_DMG_SUCCESSFUL,
    SEARCH_COIN_DETAILS_DMG_FAILURE,
    SEARCH_COIN_DETAILS_MERKLE,
    SEARCH_COIN_DETAILS_MERKLE_SUCCESSFUL,
    SEARCH_COIN_DETAILS_MERKLE_FAILURE,
    GET_EXCHANGE_RATE,
    GET_EXCHANGE_RATE_SUCCESS,
    GET_EXCHANGE_RATE_FAILURE
} from '../actions/types';

/* initial state */
const INITIAL_STATE = {
    dataCrystal: {},
    dataBIG: {},
    dataScoreChain: {},
    dataCoinfirm: {},
    dataCipher: {},
    dataElliptic: {},
    dataChainalysis: {},
    dataEY: {},
    dataDMG:{},
    dataMerkle: {},
    loadingCrystal: false,
    loadingBig: false,
    loadingScoreChain: false,
    loadingCoinfirm: false,
    loadingCipher: false,
    loadingElliptic: false,
    loadingChainalysis: false,
    loadingDMG:false,
    loadingEY: false,
    loadingMerkle: false,
    selectedCoin: 'BTC',
    selectedCoinTypeId:1,
    mode: 'off',
    loading: false,
    supportedProviders: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SEARCH_COIN_DETAILS_CRYSTAL:
            return {...state, loadingCrystal: true};
        case SEARCH_COIN_DETAILS_CRYSTAL_SUCCESSFUL:
            return {...state, dataCrystal: action.payload, loadingCrystal: false};
        case SEARCH_COIN_DETAILS_CRYSTAL_FAILURE:
            return {...state, dataCrystal: action.payload, loadingCrystal: false};

        case SEARCH_COIN_DETAILS_BIG:
            return {...state, loadingBig: true};
        case SEARCH_COIN_DETAILS_BIG_SUCCESSFUL:
            return {...state, dataBIG: action.payload, loadingBig: false};
        case SEARCH_COIN_DETAILS_BIG_FAILURE:
            return {...state, dataBIG: action.payload, loadingBig: false};

        case SEARCH_COIN_DETAILS_SCORECHAIN:
            return {...state, loadingScoreChain: true};
        case SEARCH_COIN_DETAILS_SCORECHAIN_SUCCESSFUL:
            return {...state, dataScoreChain: action.payload, loadingScoreChain: false};
        case SEARCH_COIN_DETAILS_SCORECHAIN_FAILURE:
            return {...state, dataScoreChain: action.payload, loadingScoreChain: false};

        case SEARCH_COIN_DETAILS_COINFIRM:
            return {...state, loadingCoinfirm: true};
        case SEARCH_COIN_DETAILS_COINFIRM_SUCCESSFUL:
            return {...state, dataCoinfirm: action.payload, loadingCoinfirm: false};
        case SEARCH_COIN_DETAILS_COINFIRM_FAILURE:
            return {...state, dataCoinfirm: action.payload, loadingCoinfirm: false};

        case SEARCH_COIN_DETAILS_CIPHER:
            return {...state, loadingCipher: true};
        case SEARCH_COIN_DETAILS_CIPHER_SUCCESSFUL:
            return {...state, dataCipher: action.payload, loadingCipher: false};
        case SEARCH_COIN_DETAILS_CIPHER_FAILURE:
            return {...state, dataCipher: action.payload, loadingCipher: false};

        case SEARCH_COIN_DETAILS_ELLIPTIC:
            return {...state, loadingElliptic: true};
        case SEARCH_COIN_DETAILS_ELLIPTIC_SUCCESSFUL:
            return {...state, dataElliptic: action.payload, loadingElliptic: false};
        case SEARCH_COIN_DETAILS_ELLIPTIC_FAILURE:
            return {...state, dataElliptic: action.payload, loadingElliptic: false};

        case SEARCH_COIN_DETAILS_CHAINALYSIS:
            return {...state, loadingChainalysis: true};
        case SEARCH_COIN_DETAILS_CHAINALYSIS_SUCCESSFUL:
            return {...state, dataChainalysis: action.payload, loadingChainalysis: false};
        case SEARCH_COIN_DETAILS_CHAINALYSIS_FAILURE:
            return {...state, dataChainalysis: action.payload, loadingChainalysis: false};

        case SEARCH_COIN_DETAILS_EY:
            return {...state, loadingEY: true};
        case SEARCH_COIN_DETAILS_EY_SUCCESSFUL:
            return {...state, dataEY: action.payload, loadingEY: false};
        case SEARCH_COIN_DETAILS_EY_FAILURE:
            return {...state, dataEY: action.payload, loadingEY: false};

        case SEARCH_COIN_DETAILS_DMG:
            return {...state, loadingDMG: true};
        case SEARCH_COIN_DETAILS_DMG_SUCCESSFUL:
            return {...state, dataDMG: action.payload, loadingDMG: false};
        case SEARCH_COIN_DETAILS_DMG_FAILURE:
            return {...state, dataDMG: action.payload, loadingDMG: false};

        case SEARCH_COIN_DETAILS_MERKLE:
            return {...state, loadingMerkle: true};
        case SEARCH_COIN_DETAILS_MERKLE_SUCCESSFUL:
            return {...state, dataMerkle: action.payload, loadingMerkle: false};
        case SEARCH_COIN_DETAILS_MERKLE_FAILURE:
            return {...state, dataMerkle: action.payload, loadingMerkle: false};

        case GET_SELECTED_COIN:
            return {...state, selectedCoin: action.payload};
        case GET_API_MODE:
            return {...state, mode: action.payload};

        case GET_SUPPORTED_COIN_PROVIDERS:
            return {...state, loading: true};
        case GET_SUPPORTED_COIN_PROVIDERS_SUCCESS:
            return {...state, supportedProviders: action.payload, loading: false};
        case GET_SUPPORTED_COIN_PROVIDERS_FAILURE:
            return {...state, supportedProviders: action.payload, loading: false};
        case SAVE_SELECTED_COIN_TYPE_ID:
            return {...state,selectedCoinTypeId: action.payload };

        case GET_EXCHANGE_RATE:
            return {...state,loadingGetExchangeRate:true}
        case GET_EXCHANGE_RATE_SUCCESS:
            return {...state, exchangeRateData:action.payload,loadingGetExchangeRate:false}
        case GET_EXCHANGE_RATE_FAILURE:
            return {...state, exchangeRateData:action.payload,loadingGetExchangeRate:false}
        default:
            return state;
    }
};
