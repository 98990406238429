/*
* Action For news
* */

import {
  GET_NEWS_LIST,
  GET_NEWS_LIST_SUCCESS,
  GET_NEWS_LIST_FAILURE,
  GET_MORE_NEWS
} from './types';

export const fetchNews = (payload) => ({
  type: GET_NEWS_LIST,
  payload,
});

export const newsSuccess = (payload) => ({
  type: GET_NEWS_LIST_SUCCESS,
  payload,
});

export const newsFailure = (payload) => ({
  type: GET_NEWS_LIST_FAILURE,
  payload,
});

export const loadMoreNew = (payload) => ({
  type: GET_MORE_NEWS,
  payload
})
