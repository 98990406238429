// scoring history Reducer

import {
    GET_SCORING_HISTORY,
    GET_SCORING_HISTORY_SUCCESS,
    GET_SCORING_HISTORY_FAILURE
} from '../actions/types';

const INIT_STATE = {
    scoringHistory: {},
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SCORING_HISTORY:
            return {...state, loading: true};
        case GET_SCORING_HISTORY_SUCCESS:
            return {...state, scoringHistory: action.payload, loading: false};
        case GET_SCORING_HISTORY_FAILURE:
            return {...state, scoringHistory: action.payload, loading: false};
        default:
            return state;
    }
}