/*
* Action For auto complete address
* */

import {GET_ADDRESS, GET_ADDRESS_FAILURE, GET_ADDRESS_SUCCESS} from './types';

export const fetchAddress = (payload) => ({
    type: GET_ADDRESS,
    payload,
});

export const addressSuccess = (payload) => ({
    type: GET_ADDRESS_SUCCESS,
    payload,
});

export const addressFailure = (payload) => ({
    type: GET_ADDRESS_FAILURE,
    payload,
});
