/*
* Reducer For Forgot Password
* */

import {
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_REQUEST_SUCCESS,
    FORGOT_PASSWORD_REQUEST_FAILURE,
} from '../actions/types';

const INITIAL_STATE = {
    data: null,
    loading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD_REQUEST:
            return {...state, loading: true};
        case FORGOT_PASSWORD_REQUEST_SUCCESS:
            return {...state, data: action.payload, loading: false};
        case FORGOT_PASSWORD_REQUEST_FAILURE:
            return {...state, data: action.payload, loading: false};
        default:
            return state;
    }
};
