/* Axios Config */

import axios from 'axios';
import {removeToken} from "./helper";
import {toast} from "react-toastify";

export function setAuthToken(token) {
  axios.defaults.headers.common['Authorization'] = '';
  delete axios.defaults.headers.common['Authorization'];

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
}

axios.interceptors.response.use((response) => {
    return response;
}, function (error) {
  if (error.response) {
    if (error.response.status === 401) {
      removeToken();
      window.location = "/signin";
      toast.error('Authorization token has been expired');
    }
  }
  return Promise.reject(error.response)
});