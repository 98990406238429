/*https://cors-anywhere.herokuapp.com/
* Saga to get market cap data
* */

import {all, put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';

import {GET_MARKET_CAP_DATA} from '../actions/types';
import {marketCapSuccess, marketCapFailure} from '../actions';

function* getMarketCapData(action) {
    try {
        const res = yield axios.get(`https://cors-anywhere.herokuapp.com/https://cmaapi2.coinmarketapp.co.uk/v1/tickers/` + action.payload);
        yield put(marketCapSuccess(res.data));
    } catch (e) {
        yield put(marketCapFailure(e));
    }
}

/* watch function for Get market cap data */
export function* watchMarketCapApi() {
    yield takeEvery(GET_MARKET_CAP_DATA, getMarketCapData);
}

export default function* rootSaga() {

    yield all([
        watchMarketCapApi(),
    ]);
}
