/*
* Saga for search coin details
* */

import {all, put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';

import {
    GET_SUPPORTED_COIN_PROVIDERS,
    SEARCH_COIN_DETAILS_BIG,
    SEARCH_COIN_DETAILS_CHAINALYSIS,
    SEARCH_COIN_DETAILS_CIPHER,
    SEARCH_COIN_DETAILS_COINFIRM,
    SEARCH_COIN_DETAILS_CRYSTAL,
    SEARCH_COIN_DETAILS_ELLIPTIC,
    SEARCH_COIN_DETAILS_EY,
    SEARCH_COIN_DETAILS_SCORECHAIN,
    SEARCH_COIN_DETAILS_DMG,
    SEARCH_COIN_DETAILS_MERKLE,
} from '../actions/types';
import {
    getSearchCoinDetailsBIGFail,
    getSearchCoinDetailsBIGSuccess,
    getSearchCoinDetailsChainalysisFail,
    getSearchCoinDetailsChainalysisSuccess,
    getSearchCoinDetailsCipherFail,
    getSearchCoinDetailsCipherSuccess,
    getSearchCoinDetailsCoinfirmFail,
    getSearchCoinDetailsCoinfirmSuccess,
    getSearchCoinDetailsCrystalFail,
    getSearchCoinDetailsCrystalSuccess,
    getSearchCoinDetailsEllipticFail,
    getSearchCoinDetailsEllipticSuccess,
    getSearchCoinDetailsEYFail,
    getSearchCoinDetailsEYSuccess,
    getSearchCoinDetailsScorechainFail,
    getSearchCoinDetailsScorechainSuccess,
    getSupportedCoinProvidersFailure,
    getSupportedCoinProvidersSuccess,
    getSearchCoinDetailsDMGSuccess,
    getSearchCoinDetailsDMGFail,
    getSearchCoinDetailsMerkleSuccess,
    getSearchCoinDetailsMerkleFail
} from '../actions';
import {apiUrl} from '../config';

function* searchCoinDetailsCrystal(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/scoring`, action.payload);
        yield put(getSearchCoinDetailsCrystalSuccess(res.data));
    } catch (e) {
        yield put(getSearchCoinDetailsCrystalFail(e));
    }
}

function* searchCoinDetailsBig(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/scoring`, action.payload);
        yield put(getSearchCoinDetailsBIGSuccess(res.data));
    } catch (e) {
        yield put(getSearchCoinDetailsBIGFail(e));
    }
}

function* searchCoinDetailsScoreChain(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/scoring`, action.payload);
        yield put(getSearchCoinDetailsScorechainSuccess(res.data));
    } catch (e) {
        yield put(getSearchCoinDetailsScorechainFail(e));
    }
}

function* searchCoinDetailsCoinfirm(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/scoring`, action.payload);
        yield put(getSearchCoinDetailsCoinfirmSuccess(res.data));
    } catch (e) {
        yield put(getSearchCoinDetailsCoinfirmFail(e));
    }
}

function* searchCoinDetailsChipher(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/scoring`, action.payload);
        yield put(getSearchCoinDetailsCipherSuccess(res.data));
    } catch (e) {
        yield put(getSearchCoinDetailsCipherFail(e));
    }
}

function* searchCoinDetailsElliptic(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/scoring`, action.payload);
        yield put(getSearchCoinDetailsEllipticSuccess(res.data));
    } catch (e) {
        yield put(getSearchCoinDetailsEllipticFail(e));
    }
}

function* searchCoinDetailsChainalysys(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/scoring`, action.payload);
        yield put(getSearchCoinDetailsChainalysisSuccess(res.data));
    } catch (e) {
        yield put(getSearchCoinDetailsChainalysisFail(e));
    }
}

function* searchCoinDetailsDMG(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/scoring`, action.payload);
        yield put(getSearchCoinDetailsDMGSuccess(res.data));
    } catch (e) {
        yield put(getSearchCoinDetailsDMGFail(e));
    }
}

function* searchCoinDetailsMerkle(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/scoring`, action.payload);
        yield put(getSearchCoinDetailsMerkleSuccess(res.data));
    } catch (e) {
        yield put(getSearchCoinDetailsMerkleFail(e));
    }
}

function* searchCoinDetailsEY(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/scoring`, action.payload);
        yield put(getSearchCoinDetailsEYSuccess(res.data));
    } catch (e) {
        yield put(getSearchCoinDetailsEYFail(e));
    }
}


function* getSupportedCoinProviders(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/coins-support`, action.payload);
        yield put(getSupportedCoinProvidersSuccess(res.data.data));
    } catch (e) {
        yield put(getSupportedCoinProvidersFailure(e));
    }
}

/* watch function for Search coin details for Crystal */
export function* watchCoinSearchDetailsCrystalApi() {
    yield takeEvery(SEARCH_COIN_DETAILS_CRYSTAL, searchCoinDetailsCrystal);
}

/* watch function for Search coin details for Big */
export function* watchCoinSearchDetailsBIGApi() {
    yield takeEvery(SEARCH_COIN_DETAILS_BIG, searchCoinDetailsBig);
}

/* watch function for Search coin details for Scorechain */
export function* watchCoinSearchDetailsScoreChainApi() {
    yield takeEvery(SEARCH_COIN_DETAILS_SCORECHAIN, searchCoinDetailsScoreChain);
}

/* watch function for Search coin details for Coinfirm */
export function* watchCoinSearchDetailsCoinfirmApi() {
    yield takeEvery(SEARCH_COIN_DETAILS_COINFIRM, searchCoinDetailsCoinfirm);
}

/* watch function for Search coin details for Chipher trace */
export function* watchCoinSearchDetailsCipherApi() {
    yield takeEvery(SEARCH_COIN_DETAILS_CIPHER, searchCoinDetailsChipher);
}

/* watch function for Search coin details for Elliptic */
export function* watchCoinSearchDetailsEllipticApi() {
    yield takeEvery(SEARCH_COIN_DETAILS_ELLIPTIC, searchCoinDetailsElliptic);
}

/* watch function for Search coin details for Chainalysys */
export function* watchCoinSearchDetailsChainalysysApi() {
    yield takeEvery(SEARCH_COIN_DETAILS_CHAINALYSIS, searchCoinDetailsChainalysys);
}

/* watch function for Search coin details for EY */
export function* watchCoinSearchDetailsEYApi() {
    yield takeEvery(SEARCH_COIN_DETAILS_EY, searchCoinDetailsEY);
}

/* watch function for Search coin details for EY */
export function* watchCoinSearchDetailsDMGApi() {
    yield takeEvery(SEARCH_COIN_DETAILS_DMG, searchCoinDetailsDMG);
}

/* watch function for Search coin details for Merkle */
export function* watchCoinSearchDetailsMerkleApi() {
    yield takeEvery(SEARCH_COIN_DETAILS_MERKLE, searchCoinDetailsMerkle);
}


/* watch function for Get Supported coin Provider */
export function* watchSupportedCoinProviderAPI() {
    yield takeEvery(GET_SUPPORTED_COIN_PROVIDERS, getSupportedCoinProviders);
}



export default function* rootSaga() {
    yield all([
        watchCoinSearchDetailsCrystalApi(),
        watchCoinSearchDetailsBIGApi(),
        watchCoinSearchDetailsScoreChainApi(),
        watchCoinSearchDetailsCoinfirmApi(),
        watchCoinSearchDetailsCipherApi(),
        watchCoinSearchDetailsEllipticApi(),
        watchCoinSearchDetailsChainalysysApi(),
        watchCoinSearchDetailsEYApi(),
        watchSupportedCoinProviderAPI(),
        watchCoinSearchDetailsDMGApi(),
        watchCoinSearchDetailsMerkleApi(),
    ]);
}
