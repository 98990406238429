/*
* Action Super Admin Header
* */

import {GET_COUNT_ADMIN, GET_COUNT_ADMIN_FAILURE, GET_COUNT_ADMIN_SUCCESS} from "./types";

export const getAdminCount = (payload) => ({
    type: GET_COUNT_ADMIN,
    payload
});

export const getAdminCountSuccess = (payload) => ({
    type: GET_COUNT_ADMIN_SUCCESS,
    payload
});

export const getAdminCountFail = (payload) => ({
    type: GET_COUNT_ADMIN_FAILURE,
    payload
});