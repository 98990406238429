/*Saga for backoffice admin*/


//Global imports
import {all, put, takeEvery} from 'redux-saga/effects'
import axios from 'axios';
// User specific imports
import {
    GET_USERS,
    ADD_USER,
    DELETE_USER,
    GET_API_KEY,
    ADD_API_KEY,
    DELETE_API_KEY
} from "../actions/types";
import {
    addUserFailure,
    addUserSuccess,
    getUser,
    getUserSuccess,
    getUserFailure,
    deleteUserFailure,
    deleteUserSuccess,
    getAPI,
    getApiFailure,
    getApiSuccess,
    addApiKeyFailure,
    addApiKeySuccess,
    deleteApiKeyFailure,
    deleteApiKeySuccess
} from "../actions";
import {apiUrl} from '../config/helper';
import {toast} from 'react-toastify';

function* fetchUser(actions) {
    const url = actions.payload.page === undefined ? `${apiUrl}/api/users` : `${apiUrl}/api/users?page=${actions.payload.page}`;
    try {
        const res = yield axios.post(url, {"company_id": actions.payload.company_id});
        yield put(getUserSuccess(res.data));
    } catch (e) {
        yield put(getUserFailure(e));
    }
}

function* deleteUser(actions) {
    const {id, cmp_id} = actions.payload;
    try {
        const res = yield axios.get(`${apiUrl}/api/back-office/delete-user/${id}`);
        yield put(deleteUserSuccess(res.data));
        yield put(getUser({company_id: cmp_id}));
        toast.success('User deleted successfully!');
    } catch (e) {
        yield put(deleteUserFailure(e));
    }
}

function* addUser(actions) {

    const {user_id, cmp_id} = actions.payload;
    try {
        const res = yield axios.post(`${apiUrl}/api/back-office/add-user/${user_id}`, actions.payload);
        yield put(addUserSuccess(res.data));
        yield put(getUser({company_id: cmp_id.company_id}));
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
        if (res.data.meta.status === 1) toast.success(res.data.meta.message)
    } catch (e) {
        yield put(addUserFailure(e));
    }
}

function* fetchApi() {
    try {
        const res = yield axios.get(`${apiUrl}/api/back-office/api-keys`);
        yield put(getApiSuccess(res.data));
    } catch (e) {
        yield put(getApiFailure(e));
    }
}

function* addApi(actions) {
    try {
        const res = yield axios.post(`${apiUrl}/api/back-office/create-api-key`, actions.payload);
        yield put(addApiKeySuccess(res.data));
        yield put(getAPI());
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
        if (res.data.meta.status === 1) toast.success(res.data.meta.message)
    } catch (e) {
        yield put(addApiKeyFailure(e));
    }
}

function* deleteApi(actions) {
    try {
        const res = yield axios.post(`${apiUrl}/api/back-office/delete-api-keys`, actions.payload);
        yield put(deleteApiKeySuccess(res.data));
        yield put(getAPI());
        toast.success('API key  deleted successfully!');
    } catch (e) {
        yield put(deleteApiKeyFailure(e));
    }
}


/* watch function for Get users List */
export function* watchUserListApi() {
    yield takeEvery(GET_USERS, fetchUser);
}

/* watch function for delete users  */
export function* watchDeleteUserApi() {
    yield takeEvery(DELETE_USER, deleteUser);
}

/* watch function for add user */
export function* watchAddUserApi() {
    yield takeEvery(ADD_USER, addUser);
}

/* watch function for Get api List */
export function* watchApiListApi() {
    yield takeEvery(GET_API_KEY, fetchApi);
}

/* watch function for delete api  */
export function* watchDeleteApi_Api() {
    yield takeEvery(DELETE_API_KEY, deleteApi);
}

/* watch function for add user */
export function* watchAddApi_Api() {
    yield takeEvery(ADD_API_KEY, addApi);
}

export default function* rootSaga() {
    yield all([
        watchUserListApi(),
        watchDeleteUserApi(),
        watchAddUserApi(),
        watchApiListApi(),
        watchDeleteApi_Api(),
        watchAddApi_Api(),
    ]);
};