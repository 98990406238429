/*
* Reducer for auto complete address
* */

import {
    GET_ADDRESS,
    GET_ADDRESS_FAILURE,
    GET_ADDRESS_SUCCESS
} from '../actions/types';

/* initial state */
const INITIAL_STATE = {
    data: null,
    loading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_ADDRESS:
            return {...state, loading: true};
        case GET_ADDRESS_SUCCESS:
            return {...state, data: action.payload.results, loading: false};
        case GET_ADDRESS_FAILURE:
            return {...state, data: action.payload, loading: false};
        default:
            return state;
    }
};
