/*
* Saga to get balance
* */

import {all, put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';

import {GET_ADDRESS} from '../actions/types';
import {addressSuccess, addressFailure} from '../actions';

let cancel;

function* getAddress(action) {
    cancel && cancel();
    try {
        const res = yield axios.get(`https://api.smartbit.com.au/v1/blockchain/search?q=` + action.payload.address, {
            cancelToken: new axios.CancelToken((c) => {
                cancel = c
            })
        });
        yield put(addressSuccess(res.data));
    } catch (e) {
        yield put(addressFailure(e));
    }
}

/* watch function for Get Address */
export function* watchGetAddressApi() {
    yield takeEvery(GET_ADDRESS, getAddress);
}

export default function* rootSaga() {
    yield all([
        watchGetAddressApi(),
    ]);
}
