/*
* reducer to get balance
* */

import {
    GET_BALANCE,
    GET_BALANCE_FAILURE,
    GET_BALANCE_SUCCESS,
    GET_TRANSACTIONS,
    GET_TRANSACTIONS_FAILURE,
    GET_TRANSACTIONS_SUCCESS,
    GET_BALANCE_GRAPH_FAILURE,
    GET_BALANCE_GRAPH_SUCCESS,
    GET_BALANCE_GRAPH,
    GET_WEB_ANALYSIS,
    GET_WEB_ANALYSIS_SUCCESS,
    GET_WEB_ANALYSIS_FAILURE,
    GET_TRANSACTIONS_ADDRESS_SUCCESS,
    GET_TRANSACTIONS_ADDRESS,
    GET_TRANSACTIONS_ADDRESS_FAILURE,
    GET_CREDIT,
    GET_CREDIT_FAILURE,
    GET_CREDIT_SUCCESS,
    SEARCH_COIN_ADDRESS,
    SEARCH_COIN_ADDRESS_FAILURE,
    SEARCH_COIN_ADDRESS_SUCCESS,
    REGISTER_HASH, REGISTER_HASH_SUCCESS, REGISTER_HASH_FAILURE
} from '../actions/types';

/* initial state */
const INITIAL_STATE = {
    data: '',
    loading: false,
    transactionData: {},
    transactionLoading: false,
    graphData: '',
    webAnalysisData: null,
    webLoader: false,
    transactionAddressData: null,
    transactionAddressLoading: false,
    creditData: '',
    creditLoading: '',
    searchAddressData: {},
    searchAddressLoading: false,
    hash:''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_BALANCE:
            return {...state, loading: true};
        case GET_BALANCE_SUCCESS:
            return {...state, data: action.payload, loading: false};
        case GET_BALANCE_FAILURE:
            return {...state, data: action.payload, loading: false};

        case GET_TRANSACTIONS:
            return {...state, loading: true};
        case GET_TRANSACTIONS_SUCCESS:
            return {...state, transactionData: action.payload, loading: false};
        case GET_TRANSACTIONS_FAILURE:
            return {...state, transactionData: action.payload, loading: false};

        case GET_BALANCE_GRAPH:
            return {...state, loading: true};
        case GET_BALANCE_GRAPH_SUCCESS:
            return {...state, graphData: action.payload.data.data, loading: false};
        case GET_BALANCE_GRAPH_FAILURE:
            return {...state, graphData: action.payload, loading: false};

        case GET_WEB_ANALYSIS:
            return {...state, webLoader: true};
        case GET_WEB_ANALYSIS_SUCCESS:
            return {...state, webAnalysisData: action.payload, webLoader: false};
        case GET_WEB_ANALYSIS_FAILURE:
            return {...state, webAnalysisData: action.payload, webLoader: false};

        case GET_TRANSACTIONS_ADDRESS:
            return {...state, transactionAddressLoading: true};
        case GET_TRANSACTIONS_ADDRESS_SUCCESS:
            return {...state, transactionAddressData: action.payload.data, transactionAddressLoading: false};
        case GET_TRANSACTIONS_ADDRESS_FAILURE:
            return {...state, transactionAddressData: action.payload.data, transactionAddressLoading: false};

        case GET_CREDIT:
            return {...state, creditLoading: true};
        case GET_CREDIT_SUCCESS:
            return {...state, creditData: action.payload.data, creditLoading: false};
        case GET_CREDIT_FAILURE:
            return {...state, creditData: action.payload.data, creditLoading: false};

        case  SEARCH_COIN_ADDRESS:
            return {...state, loadingScoreChainAddress: true};
        case SEARCH_COIN_ADDRESS_SUCCESS:
            return {...state, searchAddressData: action.payload, searchAddressLoading: false};
        case SEARCH_COIN_ADDRESS_FAILURE:
            return {...state, searchAddressData: action.payload, searchAddressLoading: false};

        case REGISTER_HASH:
            return  {...state,loading:true};
        case REGISTER_HASH_SUCCESS:
            return {...state, hash: action.payload, loading: false};
        case REGISTER_HASH_FAILURE:
            return {...state, hash: action.payload, loading: false};

        default:
            return state;
    }
};
