import axios from "axios";
import {GET_CRYPTO_MARKET} from '../actions/types'
import {fetchCryptoMarketSuccess, fetchCryptoMarketFailure} from "../actions";
import {all, put, takeEvery} from 'redux-saga/effects'
import {chunk} from 'lodash'

function* getCryptoMarket(actions) {
  console.log('call me');
  try {
    const res = yield axios.get(
      `https://cors-anywhere.herokuapp.com/https://web-api.coinmarketcap.com/v1/cryptocurrency/listings/latest?start=1&sort_dir=desc&limit=100&convert=USD`, actions.payload);
    const allCryptoList = chunk(res.data.data, 18)
    const currentCryptoList = allCryptoList[0]
    console.log(currentCryptoList);
    console.log(allCryptoList);
    const payload = {
      allCryptoList,
      currentCryptoList
    }
    yield put(fetchCryptoMarketSuccess(payload))
  } catch (e) {
    yield put(fetchCryptoMarketFailure(e))
  }
}

export function* watchGetCryptoMarket() {
  yield takeEvery(GET_CRYPTO_MARKET, getCryptoMarket)
}

export default function* rootSaga() {
  yield all([
    watchGetCryptoMarket()
  ])
}
