/*
* Saga for SignIn
* */

import {all, put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';
import {toast} from "react-toastify";

import {LOGIN_REQUEST, LOGOUT_REQUEST} from '../actions/types';
import {getAPIMode, loginRequestFail, loginRequestSuccess, resetAction, setCurrentUser, getToggleStatus} from '../actions';
import {apiUrl, removeToken, setToken, setUserType, setAuthToken} from '../config';

function* loginRequest(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/login`, action.payload);

        yield setToken(res.data.token);
        yield setAuthToken(res.data.token);
        if (res.data.meta.status === 1) {
            const user = yield axios.get(`${apiUrl}/api/login-user`);
            const toggle = yield axios.get(`${apiUrl}/api/toggle-status`);
            yield put(getToggleStatus(toggle.data.data.status));
            if(!toggle.data.data.status) yield put(getAPIMode('on'))
            yield put(setCurrentUser(user.data.data));
            yield setUserType(user.data.data.type);
        }
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
        if (res.data.meta.status === 1) toast.success(res.data.meta.message);
        yield put(loginRequestSuccess(res.data));
    } catch (e) {
        yield put(loginRequestFail(e));
    }
}

function* logoutRequest() {
    try {
        yield removeToken();
        yield put(resetAction())
    } catch (e) {
        toast.error('Something went wrong. Please try again!');
    }
}

/* watch function for sign in request */
export function* watchLoginRequestAPI() {
    yield takeEvery(LOGIN_REQUEST, loginRequest);
}

/* watch function for log out request */
export function* watchLogoutRequestAPI() {
    yield takeEvery(LOGOUT_REQUEST, logoutRequest);
}

export default function* rootSaga() {
    yield all([
        watchLoginRequestAPI(),
        watchLogoutRequestAPI()
    ]);
}
