/*
* Actions File For monitoring
* */

import {
    ADD_EDIT_MONITORING_USER,
    ADD_EDIT_MONITORING_USER_FAILURE,
    ADD_EDIT_MONITORING_USER_SUCCESS,
    ADD_MONITORING,
    ADD_MONITORING_ADDRESS,
    ADD_MONITORING_ALERT,
    COMMENT_ADD_UPDATE_MONITORING,
    COMMENT_LIST_MONITORING,
    COMMENT_LIST_MONITORING_FAILURE,
    COMMENT_LIST_MONITORING_SUCCESS,
    DELETE_COMMENT_MONITORING,
    DELETE_MONITOR_ADDRESS,
    DELETE_MONITORING_RECORD,
    GET_MONITORING_BY_ID,
    GET_MONITORING_BY_ID_FAILURE,
    GET_MONITORING_BY_ID_SUCCESS,
    GET_MONITORING_LIST,
    GET_MONITORING_LIST_FAILURE,
    GET_MONITORING_LIST_SUCCESS,
    GET_SCORE_FOR_MONITORING_ADDRESS,
    GET_SCORE_FOR_MONITORING_ADDRESS_FAILURE,
    GET_SCORE_FOR_MONITORING_ADDRESS_SUCCESS,
    REMOVE_SCORE_FROM_ADDRESS,
    SENT_SCORE_ADDRESS_PROVIDER,
    SET_NOTIFICATION_ALERT
} from './types'

export const getMonitoringList = (payload) => ({
    type: GET_MONITORING_LIST,
    payload
});

export const getMonitoringListSuccess = (payload) => ({
    type: GET_MONITORING_LIST_SUCCESS,
    payload
});

export const getMonitoringListFail = (payload) => ({
    type: GET_MONITORING_LIST_FAILURE,
    payload
});

export const setNotificationAlert = (payload) => ({
    type: SET_NOTIFICATION_ALERT,
    payload
});

export const deleteMonitoringRecord = (payload) => ({
    type: DELETE_MONITORING_RECORD,
    payload
});

export const addEditMonitoringUser = (payload) => ({
    type: ADD_EDIT_MONITORING_USER,
    payload
});

export const addEditMonitoringUserSuccess = (payload) => ({
    type: ADD_EDIT_MONITORING_USER_SUCCESS,
    payload
});

export const addEditMonitoringUserFail = (payload) => ({
    type: ADD_EDIT_MONITORING_USER_FAILURE,
    payload
});

export const getMonitoringById = (payload) => ({
    type: GET_MONITORING_BY_ID,
    payload
});

export const getMonitoringByIdSuccess = (payload) => ({
    type: GET_MONITORING_BY_ID_SUCCESS,
    payload
});

export const getMonitoringByIdFail = (payload) => ({
    type: GET_MONITORING_BY_ID_FAILURE,
    payload
});

export const addMonitoring = (payload) => ({
    type: ADD_MONITORING,
    payload
});

export const deleteMonitorAddress = (payload) => ({
    type: DELETE_MONITOR_ADDRESS,
    payload
});

export const addMonitoringAddress = (payload) => ({
    type: ADD_MONITORING_ADDRESS,
    payload
});

export const addMonitoringAlert = (payload) => ({
    type: ADD_MONITORING_ALERT,
    payload
});

export const removeScoreFromAddress = (payload) => ({
    type: REMOVE_SCORE_FROM_ADDRESS,
    payload
});

export const getScoreForMonitoringAddress = (payload) => ({
    type: GET_SCORE_FOR_MONITORING_ADDRESS,
    payload
});

export const getScoreForMonitoringAddressSuccess = (payload) => ({
    type: GET_SCORE_FOR_MONITORING_ADDRESS_SUCCESS,
    payload
});

export const getScoreForMonitoringAddressFail = (payload) => ({
    type: GET_SCORE_FOR_MONITORING_ADDRESS_FAILURE,
    payload
});

export const sentScoreAddressProvider = (payload) => ({
    type: SENT_SCORE_ADDRESS_PROVIDER,
    payload
});

export const commentAddUpdateMonitoring = (payload) => ({
    type: COMMENT_ADD_UPDATE_MONITORING,
    payload
});

export const commentListMonitoring = (payload) => ({
    type: COMMENT_LIST_MONITORING,
    payload
});

export const commentListMonitoringSuccess = (payload) => ({
    type: COMMENT_LIST_MONITORING_SUCCESS,
    payload
});

export const commentListMonitoringFail = (payload) => ({
    type: COMMENT_LIST_MONITORING_FAILURE,
    payload
});

export const deleteCommentMonitoring = (payload) => ({
    type: DELETE_COMMENT_MONITORING,
    payload
});