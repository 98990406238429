/*
* Monitoring Saga File
* */

import {all, put, takeEvery} from 'redux-saga/effects';
import axios from "axios";
import {toast} from "react-toastify";

import {
    GET_MONITORING_LIST,
    SET_NOTIFICATION_ALERT,
    DELETE_MONITORING_RECORD,
    ADD_EDIT_MONITORING_USER,
    GET_MONITORING_BY_ID,
    ADD_MONITORING,
    DELETE_MONITOR_ADDRESS,
    ADD_MONITORING_ADDRESS,
    ADD_MONITORING_ALERT,
    REMOVE_SCORE_FROM_ADDRESS,
    GET_SCORE_FOR_MONITORING_ADDRESS,
    SENT_SCORE_ADDRESS_PROVIDER,
    COMMENT_ADD_UPDATE_MONITORING,
    COMMENT_LIST_MONITORING, DELETE_COMMENT_MONITORING
} from "../actions/types";
import {
    getMonitoringListSuccess,
    getMonitoringListFail,
    getMonitoringList,
    addEditMonitoringUserSuccess,
    addEditMonitoringUserFail,
    getMonitoringByIdSuccess,
    getMonitoringByIdFail,
    getMonitoringById,
    getScoreForMonitoringAddressSuccess,
    getScoreForMonitoringAddressFail, commentListMonitoringSuccess, commentListMonitoringFail, commentListMonitoring
} from '../actions';
import {apiUrl, getUserIP} from "../config";

function* getMonitoringListAPI(actions) {

    const url = actions.payload !== undefined ? `${apiUrl}/api/back-office/monitoring-list?page=${actions.payload}` : `${apiUrl}/api/back-office/monitoring-list`;
    try {
        const res = yield axios.post(url, {
            "ipAddress": getUserIP()
        });
        yield put(getMonitoringListSuccess(res.data))
    } catch (e) {
        yield put(getMonitoringListFail(e));
    }
}

function* setNotificationAlert(actions) {

    try {
        yield axios.get(`${apiUrl}/api/back-office/update-alert/${actions.payload}`);
        yield put(getMonitoringList());
        yield put(getMonitoringById({id: actions.payload}));
    } catch (e) {
        console.log(e)
    }
}

function* deleteMonitoringRecord(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/api/back-office/delete-client/${actions.payload}`);
        if (res.data.meta.status === 1) toast.success(res.data.meta.message);
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
        yield put(getMonitoringList());
        window.location.href = '/monitoring';
    } catch (e) {
        console.log(e)
    }
}

function* addEditMonitoringUser(actions) {
    const {email, name, id} = actions.payload;
    const url = id !== null ? `${apiUrl}/api/back-office/add-client/${id}` : `${apiUrl}/api/back-office/add-client`;
    try {
        const res = yield axios.post(url, {email, name});
        yield put(addEditMonitoringUserSuccess(res.data.meta));
        if (res.data.meta.status === 1) toast.success(res.data.meta.message);
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
        yield put(getMonitoringList())
    } catch (e) {
        yield put(addEditMonitoringUserFail(e));
    }
}

function* getMonitoringByIdAPI(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/api/back-office/client-detail/${actions.payload.id}`);
        yield put(getMonitoringByIdSuccess(res.data.data))
    } catch (e) {
        yield put(getMonitoringByIdFail(e))
    }
}

function* addMonitoringAPIRequest(actions) {
    try {
        const res = yield axios.post(`${apiUrl}/api/back-office/add-monitoring/${actions.payload.id}`, actions.payload);
        yield put(getMonitoringById({id: actions.payload.id}));
        yield put(getMonitoringList());
        if (res.data.meta.status === 1) toast.success(res.data.meta.message);
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
    } catch (e) {
        console.log(e);
    }
}

function* deleteMonitorAddressAPI(actions) {
    try {
        const res = yield axios.get(`${apiUrl}/api/back-office/delete-address/${actions.payload.id}`);
        yield put(getMonitoringById({id: actions.payload.client_id}));
        yield put(getMonitoringList());
        if (res.data.meta.status === 1) toast.success(res.data.meta.message);
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
    } catch (e) {
        console.log(e);
    }
}

function* addMonitoringAddress(actions) {
    const {id, coin_type, address} = actions.payload;
    try {
        const res = yield axios.post(`${apiUrl}/api/back-office/add-address/${id}`, {coin_type, address});
        yield put(getMonitoringById({id}));
        yield put(getMonitoringList());
        if (res.data.meta.status === 1) toast.success(res.data.meta.message);
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
    } catch (e) {
        console.log(e);
    }
}

function* addMonitoringAlert(actions) {
    const {id, add_alert_id, alert_type, startRangeSend, endRangeSend, startRangeReceive, endRangeReceive, remove_alert_id, toCurrency, fromCurrency} = actions.payload;
    try {
        const res = yield axios.post(`${apiUrl}/api/back-office/add-alert/${id}`, {
            add_alert_id,
            alert_type,
            send_start_amount: Number(startRangeSend),
            send_end_amount: Number(endRangeSend),
            receive_start_amount: Number(startRangeReceive),
            receive_end_amount: Number(endRangeReceive),
            remove_alert_id,
            first_currency: toCurrency,
            second_currency: fromCurrency
        });
        yield put(getMonitoringById({id}));
        yield put(getMonitoringList());
        if (res.data.meta.status === 1) toast.success(res.data.meta.message);
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
    } catch (e) {
        console.log(e);
    }
}

function* removeScoreFromAddress(actions) {
    const {provider, id, client_id} = actions.payload;
    try {
        const res = yield axios.post(`${apiUrl}/api/back-office/remove-provider/${id}`, {provider});
        yield put(getMonitoringById({id: client_id}));
        yield put(getMonitoringList());
        if (res.data.meta.status === 1) toast.success(res.data.meta.message);
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
    } catch (e) {
        console.log(e)
    }
}

function* getScoreForMonitoringAddressAPI(actions) {
    try {
        const res = yield axios.post(`${apiUrl}/api/scoring`, actions.payload);
        yield put(getScoreForMonitoringAddressSuccess(res.data))
    } catch (e) {
        yield put(getScoreForMonitoringAddressFail(e))
    }
}

function* sentAddressProviderAPI(actions) {
    const {id, client_id, provider, scoring} = actions.payload;
    try {
        yield axios.post(`${apiUrl}/api/back-office/address-provider/${id}`, {provider, scoring});
        yield put(getMonitoringById({id: client_id}));
        yield put(getMonitoringList());
    } catch (e) {
        console.log(e);
    }
}

function* commentAddEditMonitoring(actions) {
    const {id, comment, Edit_id} = actions.payload;
    const url = Edit_id ? `${apiUrl}/api/back-office/add-comment/${id}/${Edit_id}` : `${apiUrl}/api/back-office/add-comment/${id}`;
    try {
        yield axios.post(url, {comment});
        yield put(commentListMonitoring({id}));
    } catch (e) {
        console.log(e);
    }
}

function* commentListMonitoringAPI(actions) {
    const url = actions.payload.page ? `${apiUrl}/api/back-office/comment-list/${actions.payload.id}?page=${actions.payload.page}` : `${apiUrl}/api/back-office/comment-list/${actions.payload.id}`;
    try {
        const res = yield axios.get(url);
        yield put(commentListMonitoringSuccess(res.data))
    } catch (e) {
        yield put(commentListMonitoringFail(e));
    }
}

function* commentDeleteMonitoring(actions) {
    const {id, client_id} = actions.payload;
    try {
        yield axios.get(`${apiUrl}/api/back-office/delete-comment/${id}`);
        yield put(commentListMonitoring({id: client_id}))
    } catch (e) {
        console.log(e)
    }
}

export function* watchMonitoringListAPI() {
    yield takeEvery(GET_MONITORING_LIST, getMonitoringListAPI);
}

export function* watchNotificationAlert() {
    yield takeEvery(SET_NOTIFICATION_ALERT, setNotificationAlert);
}

export function* watchAddEditMonitoringAPI() {
    yield takeEvery(ADD_EDIT_MONITORING_USER, addEditMonitoringUser);
}

export function* watchDeleteMonitoringRecord() {
    yield takeEvery(DELETE_MONITORING_RECORD, deleteMonitoringRecord);
}

export function* watchGetMonitoringByIdAPI() {
    yield takeEvery(GET_MONITORING_BY_ID, getMonitoringByIdAPI);
}

export function* watchAddMonitoringAPI() {
    yield takeEvery(ADD_MONITORING, addMonitoringAPIRequest)
}

export function* watchDeleteMonitorAddress() {
    yield takeEvery(DELETE_MONITOR_ADDRESS, deleteMonitorAddressAPI)
}

export function* watchAddMonitoringAddress() {
    yield takeEvery(ADD_MONITORING_ADDRESS, addMonitoringAddress)
}

export function* watchAddMonitoringAlert() {
    yield takeEvery(ADD_MONITORING_ALERT, addMonitoringAlert)
}

export function* watchRemoveScoreFromAddress() {
    yield takeEvery(REMOVE_SCORE_FROM_ADDRESS, removeScoreFromAddress)
}

export function* watchGetScoreForMonitoringAddress() {
    yield takeEvery(GET_SCORE_FOR_MONITORING_ADDRESS, getScoreForMonitoringAddressAPI)
}

export function* watchSentScoreAddressProvider() {
    yield takeEvery(SENT_SCORE_ADDRESS_PROVIDER, sentAddressProviderAPI)
}

export function* watchCommentAddEditMonitoring() {
    yield takeEvery(COMMENT_ADD_UPDATE_MONITORING, commentAddEditMonitoring)
}

export function* watchCommentListAPI() {
    yield takeEvery(COMMENT_LIST_MONITORING, commentListMonitoringAPI)
}

export function* watchDeleteComment() {
    yield takeEvery(DELETE_COMMENT_MONITORING, commentDeleteMonitoring)
}

export default function* rootSaga() {
    yield all([
        watchMonitoringListAPI(),
        watchNotificationAlert(),
        watchDeleteMonitoringRecord(),
        watchAddEditMonitoringAPI(),
        watchGetMonitoringByIdAPI(),
        watchAddMonitoringAPI(),
        watchDeleteMonitorAddress(),
        watchAddMonitoringAddress(),
        watchAddMonitoringAlert(),
        watchRemoveScoreFromAddress(),
        watchGetScoreForMonitoringAddress(),
        watchSentScoreAddressProvider(),
        watchCommentAddEditMonitoring(),
        watchCommentListAPI(),
        watchDeleteComment()
    ])
}
