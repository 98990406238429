/*
* Action For market cap data
* */

import {GET_MARKET_CAP_DATA, GET_MARKET_CAP_DATA_FAILURE, GET_MARKET_CAP_DATA_SUCCESS} from './types';

export const fetchMarketCapData = (payload) => ({
    type: GET_MARKET_CAP_DATA,
    payload,
});

export const marketCapSuccess = (payload) => ({
    type: GET_MARKET_CAP_DATA_SUCCESS,
    payload,
});

export const marketCapFailure = (payload) => ({
    type: GET_MARKET_CAP_DATA_FAILURE,
    payload,
});