import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import { ReactComponent as SvgComponentBurger } from '../../assets/img/sidebarMenu/burger.svg'
import { ReactComponent as SvgComponentApi } from '../../assets/img/sidebarMenu/api.svg'
import { ReactComponent as SvgComponentWallet } from '../../assets/img/sidebarMenu/wallet.svg'
import { ReactComponent as SvgComponentBasket } from '../../assets/img/sidebarMenu/basket.svg'
import { ReactComponent as SvgComponentDashboard } from '../../assets/img/sidebarMenu/dashboard.svg'
import { ReactComponent as SvgComponentGroup } from '../../assets/img/sidebarMenu/group.svg'
import { ReactComponent as SvgComponentSearch } from '../../assets/img/sidebarMenu/search.svg'
import { ReactComponent as SvgComponentSettings } from '../../assets/img/sidebarMenu/settings.svg'
import { ReactComponent as SvgComponentTime } from '../../assets/img/sidebarMenu/time.svg'

const SidebarMenu = () => {
	return (
		<div className='sidebar-wraper'>
			<nav className='sidebar'>
				<ul className='sidebar-menu'>
					<li className='burger'>
						<SvgComponentBurger className='main-nav-icon' />
					</li>
					<li className={`nav-link ${useLocation().pathname.endsWith('/') ? 'active' : ''}`}>
						<Link to='/'>
							<SvgComponentDashboard className='main-nav-icon' />
						</Link>
					</li>
					<li className={`nav-link ${useLocation().pathname.includes('/searchCoin') ? 'active' : ''}`}>
						<Link to='/searchCoin'>
							<SvgComponentSearch className='main-nav-icon' />
						</Link>
					</li>
					<li className={`nav-link ${useLocation().pathname.includes('/api') ? 'active' : ''}`}>
						<Link to='/api'>
							<SvgComponentApi className='main-nav-icon' />
						</Link>
					</li>
					<li className={`nav-link ${useLocation().pathname.includes('/wallet') ? 'active' : ''}`}>
						<Link to='/wallet'>
							<SvgComponentWallet className='main-nav-icon' />
						</Link>
					</li>
					<li className={`nav-link ${useLocation().pathname.includes('/shoppingCart') ? 'active' : ''}`}>
						<Link to='/shoppingCart'>
							<SvgComponentBasket className='main-nav-icon' />
						</Link>
					</li>
					<li className={`nav-link ${useLocation().pathname.includes('/schedule') ? 'active' : ''}`}>
						<Link to='/schedule'>
							<SvgComponentTime className='main-nav-icon' />
						</Link>
					</li>
					<li className={`nav-link ${useLocation().pathname.includes('/users') ? 'active' : ''}`}>
						<Link to='/users'>
							<SvgComponentGroup className='main-nav-icon' />
						</Link>
					</li>
					<li className={`nav-link ${useLocation().pathname.includes('/settings') ? 'active' : ''}`}>
						<Link to='/settings'>
							<SvgComponentSettings className='main-nav-icon' />
						</Link>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export default SidebarMenu
