/*Created by rutu*/
import Swal from "sweetalert2";

const confirmPopup = (data, title,subtitle,cancel) => {

    Swal.fire({
        title: title,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
    }).then((result) => {
        if (result.value) {
            data();
            Swal.fire(
                'Deleted!',
                subtitle,
            )
        }
    })
};

export default confirmPopup;