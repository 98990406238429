/*
* Reducer for MYUNIAML
* */

import {
    SET_RULES,
    GET_RULES_MYUNIAML,
    GET_RULES_MYUNIAML_SUCCESS,
    GET_RULES_MYUNIAML_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
    loading: false,
    rulesUNIAML: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_RULES:
            return {...state, loading: true};
        case GET_RULES_MYUNIAML:
            return {...state, loading: true};
        case GET_RULES_MYUNIAML_SUCCESS:
            return {...state, loading: false, rulesUNIAML: action.payload};
        case GET_RULES_MYUNIAML_FAILURE:
            return {...state, loading: false, rulesUNIAML: action.payload};
        default:
            return state;
    }
}