import {GET_CRYPTO_MARKET, GET_CRYPTO_MARKET_SUCCESS, GET_CRYPTO_MARKET_FAILURE} from './types'

export const fetchCryptoMarket = (payload) => ({
  type: GET_CRYPTO_MARKET,
  payload
})

export const fetchCryptoMarketSuccess = (payload) => ({
  type: GET_CRYPTO_MARKET_SUCCESS,
  payload
})

export const fetchCryptoMarketFailure = (payload) => ({
  type: GET_CRYPTO_MARKET_FAILURE,
  payload
})
