/*
* Saga for backoffice admin created by rutu.
* */

import {all, put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';

import {GET_COUNT_ADMIN} from '../actions/types';
import {
    getAdminCountSuccess, getAdminCountFail
} from '../actions';
import {apiUrl} from '../config/helper';

function* getBackOfficeAdminCountRequest(action) {

    try {
        const res = yield axios.get(`${apiUrl}/api/back-office/total-count/${action.payload}`);
        yield put(getAdminCountSuccess(res.data.data));
    } catch (e) {
        yield put(getAdminCountFail(e));
    }
}

/*watch function for super admin count*/
export function* watchBackOfficeAdminCountAPI() {
    yield takeEvery(GET_COUNT_ADMIN, getBackOfficeAdminCountRequest);
}

export default function* rootSaga() {
    yield all([
        watchBackOfficeAdminCountAPI()
    ]);
}
