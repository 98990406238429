/*
* Reducer for sign up
* */

import {REGISTER_REQUEST, REGISTER_REQUEST_FAILURE, REGISTER_REQUEST_SUCCESS,} from '../actions/types';

/* initial state */
const INITIAL_STATE = {
    data: null,
    loading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REGISTER_REQUEST:
            return {...state, loading: true};
        case REGISTER_REQUEST_SUCCESS:
            return {...state, data: action.payload, loading: false};
        case REGISTER_REQUEST_FAILURE:
            return {...state, data: action.payload, loading: false};
        default:
            return state;
    }
};
