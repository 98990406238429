/*
* Super Admin Reducer
* */

import {
    GET_COMPANY_LIST,
    GET_COMPANY_LIST_SUCCESS,
    GET_COMPANY_LIST_FAILURE,
    GET_COMPANY_PROVIDER_LOG,
    GET_COMPANY_PROVIDER_LOG_SUCCESS,
    GET_COMPANY_PROVIDER_LOG_FAILURE,
    GET_PROVIDER_INNER_LOG,
    GET_PROVIDER_INNER_LOG_SUCCESS,
    GET_PROVIDER_INNER_LOG_FAILURE,
    ADD_COMPANY,
    ADD_COMPANY_SUCCESS,
    ADD_COMPANY_FAILURE,
    DELETE_COMPANY,
    DELETE_COMPANY_FAILURE,
    DELETE_COMPANY_SUCCESS,
    ADD_CREDIT,
    ADD_CREDIT_FAILURE,
    ADD_CREDIT_SUCCESS,
    INDIVIDUAL_USER_LIST,
    INDIVIDUAL_USER_LIST_SUCCESS,
    INDIVIDUAL_USER_LIST_FAILURE,
    ADD_CREDIT_INDIVIDUAL,
    ADD_CREDIT_INDIVIDUAL_SUCCESS,
    ADD_CREDIT_INDIVIDUAL_FAILURE,
    DELETE_INDIVIDUAL_SUCCESS,
    DELETE_INDIVIDUAL,
    DELETE_INDIVIDUAL_FAILURE,
    GET_INDIVIDUAL_PROVIDER_LOG,
    GET_INDIVIDUAL_PROVIDER_LOG_FAILURE,
    GET_INDIVIDUAL_PROVIDER_LOG_SUCCESS,
    GET_INDIVIDUAL_PROVIDER_INNER_LOG,
    GET_INDIVIDUAL_PROVIDER_INNER_LOG_FAILURE,
    GET_INDIVIDUAL_PROVIDER_INNER_LOG_SUCCESS,
    GET_TEMPLATE_ID,
    GET_TEMPLATE_ID_SUCCESS,
    GET_TEMPLATE_ID_FAILURE,
    CHANGE_TEMPLATE_ID,
    CHANGE_TEMPLATE_ID_FAILURE,
    CHANGE_TEMPLATE_ID_SUCCESS,
    GET_API_KEY_SUPERADMIN_FAILURE,
    GET_API_KEY_SUPERADMIN,
    GET_API_KEY_SUPERADMIN_SUCCESS,
    ADD_API_KEY_SUPERADMIN_FAILURE,
    ADD_API_KEY_SUPERADMIN_SUCCESS,
    ADD_API_KEY_SUPERADMIN,
    GET_COMPANY_API_KEY,
    GET_COMPANY_API_KEY_FAILURE,
    GET_COMPANY_API_KEY_SUCCESS,
    ADD_COMPANY_API_KEY,
    ADD_COMPANY_API_KEY_FAILURE,
    ADD_COMPANY_API_KEY_SUCCESS,
    ADD_INDIVIDUAL_LIMIT,
    ADD_INDIVIDUAL_LIMIT_FAILURE,
    ADD_INDIVIDUAL_LIMIT_SUCCESS
} from "../actions/types"

/*Initial State*/

const INIT_STATE = {
    cmpList: [],
    loading: false,
    cmpProviderLog: [],
    providerInnerLog: {},
    addCompanydata: {},
    deleteCompany: '',
    addCredit: {},
    individualUserList: [],
    addCreditIndividual: {},
    deleteIndividual: '',
    individualProviderLog: [],
    individualProviderInnerLog: {},
    setTemplateID: {},
    getTemplatedata: {},
    getApiKeysSuperAdmin:{},
    addApiKeys:'',
    getApiKeysCompany:{},
    addApiKeysCompany:'',
    addLimitIndividual:null

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_COMPANY_LIST:
            return {...state, loading: true};
        case GET_COMPANY_LIST_SUCCESS:
            return {...state, cmpList: action.payload, loading: false};
        case GET_COMPANY_LIST_FAILURE:
            return {...state, cmpList: action.payload, loading: false};


        case GET_COMPANY_PROVIDER_LOG:
            return {...state, loading: true};
        case GET_COMPANY_PROVIDER_LOG_SUCCESS:
            return {...state, cmpProviderLog: action.payload, loading: false};
        case GET_COMPANY_PROVIDER_LOG_FAILURE:
            return {...state, cmpProviderLog: action.payload, loading: false};


        case GET_PROVIDER_INNER_LOG:
            return {...state, loadingInner: true};
        case GET_PROVIDER_INNER_LOG_SUCCESS:
            return {...state, providerInnerLog: action.payload, loadingInner: false};
        case GET_PROVIDER_INNER_LOG_FAILURE:
            return {...state, providerInnerLog: action.payload, loadingInner: false};


        case ADD_COMPANY:
            return {...state, addCompanydata: action.payload};
        case ADD_COMPANY_SUCCESS:
            return {...state, addCompanydata: action.payload.data.meta};
        case ADD_COMPANY_FAILURE:
            return {...state, addCompanydata: action.payload};

        case DELETE_COMPANY:
            return {...state, deleteCompany: action.payload};
        case DELETE_COMPANY_SUCCESS:
            return {...state, deleteCompany: action.payload};
        case DELETE_COMPANY_FAILURE:
            return {...state, deleteCompany: action.payload};

        case ADD_CREDIT:
            return {...state, addCredit: action.payload};
        case ADD_CREDIT_SUCCESS:
            return {...state, addCredit: action.payload};
        case ADD_CREDIT_FAILURE:
            return {...state, addCredit: action.payload};

        case INDIVIDUAL_USER_LIST:
            return {...state, loading: true};
        case INDIVIDUAL_USER_LIST_SUCCESS:
            return {...state, individualUserList: action.payload, loading: false};
        case INDIVIDUAL_USER_LIST_FAILURE:
            return {...state, individualUserList: action.payload, loading: false};

        case ADD_CREDIT_INDIVIDUAL:
            return {...state, addCreditIndividual: action.payload};
        case ADD_CREDIT_INDIVIDUAL_SUCCESS:
            return {...state, addCreditIndividual: action.payload};
        case ADD_CREDIT_INDIVIDUAL_FAILURE:
            return {...state, addCreditIndividual: action.payload};

        case DELETE_INDIVIDUAL:
            return {...state, deleteIndividual: action.payload};
        case DELETE_INDIVIDUAL_SUCCESS:
            return {...state, deleteIndividual: action.payload};
        case DELETE_INDIVIDUAL_FAILURE:
            return {...state, deleteIndividual: action.payload};

        case GET_INDIVIDUAL_PROVIDER_LOG:
            return {...state, loading: true};
        case GET_INDIVIDUAL_PROVIDER_LOG_SUCCESS:
            return {...state, individualProviderLog: action.payload, loading: false};
        case GET_INDIVIDUAL_PROVIDER_LOG_FAILURE:
            return {...state, individualProviderLog: action.payload, loading: false};

        case GET_INDIVIDUAL_PROVIDER_INNER_LOG:
            return {...state, loadingInner: true};
        case GET_INDIVIDUAL_PROVIDER_INNER_LOG_SUCCESS:
            return {...state, individualProviderInnerLog: action.payload, loadingInner: false};
        case GET_INDIVIDUAL_PROVIDER_INNER_LOG_FAILURE:
            return {...state, individualProviderInnerLog: action.payload, loadingInner: false};

        case CHANGE_TEMPLATE_ID:
            return {...state};
        case CHANGE_TEMPLATE_ID_SUCCESS:
            return {...state, setTemplateID: action.payload};
        case CHANGE_TEMPLATE_ID_FAILURE:
            return {...state, setTemplateID: action.payload};

        case GET_TEMPLATE_ID:
            return {...state, loading: true};
        case GET_TEMPLATE_ID_SUCCESS:
            return {...state, getTemplatedata: action.payload, loading: false};
        case GET_TEMPLATE_ID_FAILURE:
            return {...state, getTemplatedata: action.payload, loading: false};

        case GET_API_KEY_SUPERADMIN:
            return {...state, loading: true};
        case GET_API_KEY_SUPERADMIN_SUCCESS:
            return {...state, getApiKeysSuperAdmin: action.payload, loading: false};
        case GET_API_KEY_SUPERADMIN_FAILURE:
            return {...state, getApiKeysSuperAdmin: action.payload, loading: false};

        case ADD_API_KEY_SUPERADMIN:
            return {...state, loading: true};
        case ADD_API_KEY_SUPERADMIN_SUCCESS:
            return {...state, addApiKeys: action.payload, loading: false};
        case ADD_API_KEY_SUPERADMIN_FAILURE:
            return {...state, addApiKeys: action.payload, loading: false};

        case ADD_COMPANY_API_KEY:

            return {...state, loading: true};
        case ADD_COMPANY_API_KEY_SUCCESS:

            return {...state, addApiKeysCompany: action.payload, loading: false};
        case ADD_COMPANY_API_KEY_FAILURE:
            return {...state, addApiKeysCompany: action.payload, loading: false};

        case GET_COMPANY_API_KEY:
            return {...state, loading: true};
        case GET_COMPANY_API_KEY_SUCCESS:
            return {...state, getApiKeysCompany: action.payload, loading: false};
        case GET_COMPANY_API_KEY_FAILURE:
            return {...state, getApiKeysCompany: action.payload, loading: false};

        case ADD_INDIVIDUAL_LIMIT:
            return {...state, loading: true};
        case ADD_INDIVIDUAL_LIMIT_SUCCESS:
            return {...state, addLimitIndividual: action.payload, loading: false};
        case ADD_INDIVIDUAL_LIMIT_FAILURE:
            return {...state, addLimitIndividual: action.payload, loading: false};
        default:
            return state;
    }
}