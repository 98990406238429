/*
* Action For Search Coin
* */

import {
    GET_API_MODE,
    GET_SELECTED_COIN,
    GET_SUPPORTED_COIN_PROVIDERS,
    GET_SUPPORTED_COIN_PROVIDERS_FAILURE,
    GET_SUPPORTED_COIN_PROVIDERS_SUCCESS,
    RESET_STORE,
    SAVE_SELECTED_COIN_TYPE_ID,
    SEARCH_COIN_DETAILS_BIG,
    SEARCH_COIN_DETAILS_BIG_FAILURE,
    SEARCH_COIN_DETAILS_BIG_SUCCESSFUL,
    SEARCH_COIN_DETAILS_CHAINALYSIS,
    SEARCH_COIN_DETAILS_CHAINALYSIS_FAILURE,
    SEARCH_COIN_DETAILS_CHAINALYSIS_SUCCESSFUL,
    SEARCH_COIN_DETAILS_CIPHER,
    SEARCH_COIN_DETAILS_CIPHER_FAILURE,
    SEARCH_COIN_DETAILS_CIPHER_SUCCESSFUL,
    SEARCH_COIN_DETAILS_COINFIRM,
    SEARCH_COIN_DETAILS_COINFIRM_FAILURE,
    SEARCH_COIN_DETAILS_COINFIRM_SUCCESSFUL,
    SEARCH_COIN_DETAILS_CRYSTAL,
    SEARCH_COIN_DETAILS_CRYSTAL_FAILURE,
    SEARCH_COIN_DETAILS_CRYSTAL_SUCCESSFUL,
    SEARCH_COIN_DETAILS_ELLIPTIC,
    SEARCH_COIN_DETAILS_ELLIPTIC_FAILURE,
    SEARCH_COIN_DETAILS_ELLIPTIC_SUCCESSFUL,
    SEARCH_COIN_DETAILS_EY,
    SEARCH_COIN_DETAILS_EY_FAILURE,
    SEARCH_COIN_DETAILS_EY_SUCCESSFUL,
    SEARCH_COIN_DETAILS_SCORECHAIN,
    SEARCH_COIN_DETAILS_SCORECHAIN_FAILURE,
    SEARCH_COIN_DETAILS_SCORECHAIN_SUCCESSFUL,
    SEARCH_COIN_DETAILS_DMG,
    SEARCH_COIN_DETAILS_DMG_SUCCESSFUL,
    SEARCH_COIN_DETAILS_DMG_FAILURE,
    SEARCH_COIN_DETAILS_MERKLE,
    SEARCH_COIN_DETAILS_MERKLE_SUCCESSFUL,
    SEARCH_COIN_DETAILS_MERKLE_FAILURE,
} from './types';

/* Score For Crystal */
export const getSearchCoinDetailsCrystal = (payload) => ({
    type: SEARCH_COIN_DETAILS_CRYSTAL,
    payload,
});

export const getSearchCoinDetailsCrystalSuccess = (payload) => ({
    type: SEARCH_COIN_DETAILS_CRYSTAL_SUCCESSFUL,
    payload,
});

export const getSearchCoinDetailsCrystalFail = (payload) => ({
    type: SEARCH_COIN_DETAILS_CRYSTAL_FAILURE,
    payload,
});

/* Score For BIG */
export const getSearchCoinDetailsBIG = (payload) => ({
    type: SEARCH_COIN_DETAILS_BIG,
    payload,
});

export const getSearchCoinDetailsBIGSuccess = (payload) => ({
    type: SEARCH_COIN_DETAILS_BIG_SUCCESSFUL,
    payload,
});

export const getSearchCoinDetailsBIGFail = (payload) => ({
    type: SEARCH_COIN_DETAILS_BIG_FAILURE,
    payload,
});

/* Score For Scorechain */
export const getSearchCoinDetailsScorechain = (payload) => ({
    type: SEARCH_COIN_DETAILS_SCORECHAIN,
    payload,
});

export const getSearchCoinDetailsScorechainSuccess = (payload) => ({
    type: SEARCH_COIN_DETAILS_SCORECHAIN_SUCCESSFUL,
    payload,
});

export const getSearchCoinDetailsScorechainFail = (payload) => ({
    type: SEARCH_COIN_DETAILS_SCORECHAIN_FAILURE,
    payload,
});

/* Score For Coinfirm */
export const getSearchCoinDetailsCoinfirm = (payload) => ({
    type: SEARCH_COIN_DETAILS_COINFIRM,
    payload,
});

export const getSearchCoinDetailsCoinfirmSuccess = (payload) => ({
    type: SEARCH_COIN_DETAILS_COINFIRM_SUCCESSFUL,
    payload,
});

export const getSearchCoinDetailsCoinfirmFail = (payload) => ({
    type: SEARCH_COIN_DETAILS_COINFIRM_FAILURE,
    payload,
});

/* Score For Cipher-trace */
export const getSearchCoinDetailsCipher = (payload) => ({
    type: SEARCH_COIN_DETAILS_CIPHER,
    payload,
});

export const getSearchCoinDetailsCipherSuccess = (payload) => ({
    type: SEARCH_COIN_DETAILS_CIPHER_SUCCESSFUL,
    payload,
});

export const getSearchCoinDetailsCipherFail = (payload) => ({
    type: SEARCH_COIN_DETAILS_CIPHER_FAILURE,
    payload,
});
/* Score For Elliptic */
export const getSearchCoinDetailsElliptic = (payload) => ({
    type: SEARCH_COIN_DETAILS_ELLIPTIC,
    payload,
});

export const getSearchCoinDetailsEllipticSuccess = (payload) => ({
    type: SEARCH_COIN_DETAILS_ELLIPTIC_SUCCESSFUL,
    payload,
});

export const getSearchCoinDetailsEllipticFail = (payload) => ({
    type: SEARCH_COIN_DETAILS_ELLIPTIC_FAILURE,
    payload,
});
/* Score For Chainalysis */
export const getSearchCoinDetailsChainalysis = (payload) => ({
    type: SEARCH_COIN_DETAILS_CHAINALYSIS,
    payload,
});

export const getSearchCoinDetailsChainalysisSuccess = (payload) => ({
    type: SEARCH_COIN_DETAILS_CHAINALYSIS_SUCCESSFUL,
    payload,
});

export const getSearchCoinDetailsChainalysisFail = (payload) => ({
    type: SEARCH_COIN_DETAILS_CHAINALYSIS_FAILURE,
    payload,
});
/* Score For EY */
export const getSearchCoinDetailsEY = (payload) => ({
    type: SEARCH_COIN_DETAILS_EY,
    payload,
});

export const getSearchCoinDetailsEYSuccess = (payload) => ({
    type: SEARCH_COIN_DETAILS_EY_SUCCESSFUL,
    payload,
});

export const getSearchCoinDetailsEYFail = (payload) => ({
    type: SEARCH_COIN_DETAILS_EY_FAILURE,
    payload,
});

/* Score For DMG */
export const getSearchCoinDetailsDMG = (payload) => ({
    type: SEARCH_COIN_DETAILS_DMG,
    payload,
});

export const getSearchCoinDetailsDMGSuccess = (payload) => ({
    type: SEARCH_COIN_DETAILS_DMG_SUCCESSFUL,
    payload,
});

export const getSearchCoinDetailsDMGFail = (payload) => ({
    type: SEARCH_COIN_DETAILS_DMG_FAILURE,
    payload,
});

/* Score For Merkle */
export const getSearchCoinDetailsMerkle = (payload) => ({
    type: SEARCH_COIN_DETAILS_MERKLE,
    payload,
});

export const getSearchCoinDetailsMerkleSuccess = (payload) => ({
    type: SEARCH_COIN_DETAILS_MERKLE_SUCCESSFUL,
    payload,
});

export const getSearchCoinDetailsMerkleFail = (payload) => ({
    type: SEARCH_COIN_DETAILS_MERKLE_FAILURE,
    payload,
});

export const getSelectedCoin = (payload) => ({
    type: GET_SELECTED_COIN,
    payload,
});

export const saveSelectedCoinTypeId = (payload) => ({
    type: SAVE_SELECTED_COIN_TYPE_ID,
    payload,
});

export const getAPIMode = (payload) => ({
    type: GET_API_MODE,
    payload,
});

/* Get Supported Coin Providers */

export const getSupportedCoinProviders = (payload) => ({
    type: GET_SUPPORTED_COIN_PROVIDERS,
    payload,
});

export const getSupportedCoinProvidersSuccess = (payload) => ({
    type: GET_SUPPORTED_COIN_PROVIDERS_SUCCESS,
    payload,
});

export const getSupportedCoinProvidersFailure = (payload) => ({
    type: GET_SUPPORTED_COIN_PROVIDERS_FAILURE,
    payload,
});

/*Action For Reset Redux Store*/

export const resetAction = () => ({
    type: RESET_STORE
});
