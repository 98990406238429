/*
* Saga for SignIn
* */

import {all, put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';

import {GET_COUNT_SUPER_ADMIN} from '../actions/types';
import {
    getSuperAdminCountSuccess, getSuperAdminCountFail
} from '../actions';
import {apiUrl} from '../config/helper';

function* getSuperAdminCountRequest() {
    try {
        const res = yield axios.get(`${apiUrl}/api/super-admin/company-count`);
        yield put(getSuperAdminCountSuccess(res.data.data));
    } catch (e) {
        yield put(getSuperAdminCountFail(e));
    }
}

/*watch function for super admin count*/
export function* watchsuperAdminCountAPI() {
    yield takeEvery(GET_COUNT_SUPER_ADMIN, getSuperAdminCountRequest);
}

export default function* rootSaga() {
    yield all([
        watchsuperAdminCountAPI()
    ]);
}
