import React, { Suspense, lazy } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import LoaderComponent from '../components/Loaders/loader'
import ErrorBoundry from '../components/ErrorBoundary'
import SidebarMenu from '../components/sidebarMenu/sidebarMenu'
import { RoutesPath } from './routesComponent'
import PrivateRoute from './privateRoute'

const Header = lazy(() => import('../container/header'))
const history = createBrowserHistory({ basename: '/' })

const Routes = () => (
	<Router history={history}>
		<ErrorBoundry>
			<Suspense fallback={<LoaderComponent />}>
				<Header />
				<div className='content'>
					<SidebarMenu />
					<Switch>
						{RoutesPath.map((itm) =>
							itm.private ? (
								<PrivateRoute exact path={itm.path} key={itm.path} component={itm.component} />
							) : (
								<Route exact path={itm.path} key={itm.path} component={itm.component} />
							)
						)}
						<Redirect to='/404' />
					</Switch>
				</div>
			</Suspense>
		</ErrorBoundry>
	</Router>
)

export default Routes
