/*
* Super Admin Reducer
* */

import {
    GET_COUNT_ADMIN,
    GET_COUNT_ADMIN_FAILURE,
    GET_COUNT_ADMIN_SUCCESS
} from '../actions/types';

/* initial state */
const INITIAL_STATE = {
    count: null,
    countLoading: null,
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case GET_COUNT_ADMIN:
            return {...state, countLoading: true};
        case GET_COUNT_ADMIN_SUCCESS:
            return {...state, count: action.payload, countLoading: false};
        case GET_COUNT_ADMIN_FAILURE:
            return {...state, count: action.payload, countLoading: false};
        default:
            return state;
    }
};
