/*
* Actions For Super Admin
* */

import {
    ADD_API_KEY_SUPERADMIN, ADD_API_KEY_SUPERADMIN_FAILURE, ADD_API_KEY_SUPERADMIN_SUCCESS,
    ADD_COMPANY,
    ADD_COMPANY_FAILURE,
    ADD_COMPANY_SUCCESS,
    ADD_CREDIT,
    ADD_CREDIT_FAILURE,
    ADD_CREDIT_INDIVIDUAL,
    ADD_CREDIT_INDIVIDUAL_FAILURE,
    ADD_CREDIT_INDIVIDUAL_SUCCESS,
    ADD_CREDIT_SUCCESS,
    CHANGE_TEMPLATE_ID,
    CHANGE_TEMPLATE_ID_FAILURE,
    CHANGE_TEMPLATE_ID_SUCCESS,
    DELETE_COMPANY,
    DELETE_COMPANY_FAILURE,
    DELETE_COMPANY_SUCCESS,
    DELETE_INDIVIDUAL,
    DELETE_INDIVIDUAL_FAILURE,
    DELETE_INDIVIDUAL_SUCCESS, GET_API_KEY_SUPERADMIN, GET_API_KEY_SUPERADMIN_FAILURE, GET_API_KEY_SUPERADMIN_SUCCESS,
    GET_COMPANY_LIST,
    GET_COMPANY_LIST_FAILURE,
    GET_COMPANY_LIST_SUCCESS,
    GET_COMPANY_PROVIDER_LOG,
    GET_COMPANY_PROVIDER_LOG_FAILURE,
    GET_COMPANY_PROVIDER_LOG_SUCCESS,
    GET_INDIVIDUAL_PROVIDER_INNER_LOG,
    GET_INDIVIDUAL_PROVIDER_INNER_LOG_FAILURE,
    GET_INDIVIDUAL_PROVIDER_INNER_LOG_SUCCESS,
    GET_INDIVIDUAL_PROVIDER_LOG,
    GET_INDIVIDUAL_PROVIDER_LOG_FAILURE,
    GET_INDIVIDUAL_PROVIDER_LOG_SUCCESS,
    GET_PROVIDER_INNER_LOG,
    GET_PROVIDER_INNER_LOG_FAILURE,
    GET_PROVIDER_INNER_LOG_SUCCESS,
    GET_TEMPLATE_ID,
    GET_TEMPLATE_ID_FAILURE,
    GET_TEMPLATE_ID_SUCCESS,
    INDIVIDUAL_USER_LIST,
    INDIVIDUAL_USER_LIST_FAILURE,
    INDIVIDUAL_USER_LIST_SUCCESS,
    GET_COMPANY_API_KEY,
    GET_COMPANY_API_KEY_FAILURE,
    GET_COMPANY_API_KEY_SUCCESS,
    ADD_COMPANY_API_KEY,
    ADD_COMPANY_API_KEY_FAILURE,
    ADD_COMPANY_API_KEY_SUCCESS,
    ADD_INDIVIDUAL_LIMIT,
    ADD_INDIVIDUAL_LIMIT_FAILURE,
    ADD_INDIVIDUAL_LIMIT_SUCCESS

} from './types';

/*Get Company List*/
export const getCompanyList = () => ({
    type: GET_COMPANY_LIST
});

export const getCompanyListSuccess = (payload) => ({
    type: GET_COMPANY_LIST_SUCCESS,
    payload
});

export const getCompanyListFail = (payload) => ({
    type: GET_COMPANY_LIST_FAILURE,
    payload
});

/*Get Company Provider Log*/

export const getCompanyProviderLog = (payload) => ({
    type: GET_COMPANY_PROVIDER_LOG,
    payload
});

export const getCompanyProviderLogSuccess = (payload) => ({
    type: GET_COMPANY_PROVIDER_LOG_SUCCESS,
    payload
});

export const getCompanyProviderLogFail = (payload) => ({
    type: GET_COMPANY_PROVIDER_LOG_FAILURE,
    payload
});

/*Get Provider Innder Log*/

export const getProviderInnerLog = (payload) => ({
    type: GET_PROVIDER_INNER_LOG,
    payload
});

export const getProviderInnerLogSuccess = (payload) => ({
    type: GET_PROVIDER_INNER_LOG_SUCCESS,
    payload
});

export const getProviderInnerLogFail = (payload) => ({
    type: GET_PROVIDER_INNER_LOG_FAILURE,
    payload
});

/*Add company in user tab*/

export const addCompany = (payload) => ({
    type: ADD_COMPANY,
    payload
});

export const addCompanySuccess = (payload) => ({
    type: ADD_COMPANY_SUCCESS,
    payload
});

export const addCompanyFailure = (payload) => ({
    type: ADD_COMPANY_FAILURE,
    payload
});

/*Delete company in user tab*/

export const deleteCompany = (payload) => ({
    type: DELETE_COMPANY,
    payload
});

export const deleteCompanySuccess = (payload) => ({
    type: DELETE_COMPANY_SUCCESS,
    payload
});

export const deleteCompanyFailure = (payload) => ({
    type: DELETE_COMPANY_FAILURE,
    payload
});

/*Add credit in user tab for company*/

export const addCredit = (payload) => ({
    type: ADD_CREDIT,
    payload
});

export const addCreditSuccess = (payload) => ({
    type: ADD_CREDIT_SUCCESS,
    payload
});

export const addCreditFailure = (payload) => ({
    type: ADD_CREDIT_FAILURE,
    payload
});

/*Get Individual User list*/

export const getIndividualUser = (payload) => ({
    type: INDIVIDUAL_USER_LIST,
    payload
});

export const getIndividualUserSuccess = (payload) => ({
    type: INDIVIDUAL_USER_LIST_SUCCESS,
    payload
});

export const getIndividualUserFail = (payload) => ({
    type: INDIVIDUAL_USER_LIST_FAILURE,
    payload
});

/*Add credit in user tab for individual*/

export const addCreditIndividual = (payload) => ({
    type: ADD_CREDIT_INDIVIDUAL,
    payload
});

export const addCreditIndividualSuccess = (payload) => ({
    type: ADD_CREDIT_INDIVIDUAL_SUCCESS,
    payload
});

export const addCreditIndividualFailure = (payload) => ({
    type: ADD_CREDIT_INDIVIDUAL_FAILURE,
    payload
});

/*delete individual*/

export const deleteIndividual = (payload) => ({
    type: DELETE_INDIVIDUAL,
    payload
});

export const deleteIndividualSuccess = (payload) => ({
    type: DELETE_INDIVIDUAL_SUCCESS,
    payload
});

export const deleteIndividualFailure = (payload) => ({
    type: DELETE_INDIVIDUAL_FAILURE,
    payload
});

/*Individual log*/

export const getIndividualLog = (payload) => ({
    type: GET_INDIVIDUAL_PROVIDER_LOG,
    payload
});

export const getIndividualLogSuccess = (payload) => ({
    type: GET_INDIVIDUAL_PROVIDER_LOG_SUCCESS,
    payload
});

export const getIndividualLogFailure = (payload) => ({
    type: GET_INDIVIDUAL_PROVIDER_LOG_FAILURE,
    payload
});

/*Individual inner log*/
export const getIndividualInnerLog = (payload) => ({
    type: GET_INDIVIDUAL_PROVIDER_INNER_LOG,
    payload
});

export const getIndividualInnerLogSuccess = (payload) => ({
    type: GET_INDIVIDUAL_PROVIDER_INNER_LOG_SUCCESS,
    payload
});

export const getIndividualInnerLogFailure = (payload) => ({
    type: GET_INDIVIDUAL_PROVIDER_INNER_LOG_FAILURE,
    payload
});

/*change template id*/
export const setTemplateID = (payload) => ({
    type: CHANGE_TEMPLATE_ID,
    payload
});

export const setTemplateIDSuccess = (payload) => ({
    type: CHANGE_TEMPLATE_ID_SUCCESS,
    payload
});

export const setTemplateIDFailure = (payload) => ({
    type: CHANGE_TEMPLATE_ID_FAILURE,
    payload
});

/*Get template IDs*/
export const getTemplateIDs = (payload) => ({
    type: GET_TEMPLATE_ID,
    payload
});

export const getTemplateIDsSuccess = (payload) => ({
    type: GET_TEMPLATE_ID_SUCCESS,
    payload
});

export const getTemplateIDsFailure = (payload) => ({
    type: GET_TEMPLATE_ID_FAILURE,
    payload
});

/*Get API KEYS */
export const getApiKeys = (payload) => ({
    type: GET_API_KEY_SUPERADMIN,
    payload
});

export const getApiKeysSuccess = (payload) => ({
    type:  GET_API_KEY_SUPERADMIN_SUCCESS,
    payload
});

export const getApiKeysFailure = (payload) => ({
    type: GET_API_KEY_SUPERADMIN_FAILURE,
    payload
});

/*Update API KEYS */
export const addApiKeys = (payload) => ({
    type: ADD_API_KEY_SUPERADMIN,
    payload
});

export const addApiKeysSuccess = (payload) => ({
    type: ADD_API_KEY_SUPERADMIN_SUCCESS,
    payload
});

export const addApiKeysFailure = (payload) => ({
    type: ADD_API_KEY_SUPERADMIN_FAILURE,
    payload
});

/*Get Company API KEYS */
export const getCompanyApiKeys = (payload) => ({
    type: GET_COMPANY_API_KEY,
    payload
});

export const getCompanyApiKeysSuccess = (payload) => ({
    type: GET_COMPANY_API_KEY_SUCCESS,
    payload
});

export const getCompanyApiKeysFailure = (payload) => ({
    type: GET_COMPANY_API_KEY_FAILURE,
    payload
});

/*Add Company API KEYS */
export const addCompanyApiKeys = (payload) => ({
    type: ADD_COMPANY_API_KEY,
    payload
});

export const addCompanyApiKeysSuccess = (payload) => ({
    type: ADD_COMPANY_API_KEY_SUCCESS,
    payload
});

export const addCompanyApiKeysFailure = (payload) => ({
    type: ADD_COMPANY_API_KEY_FAILURE,
    payload
});

/*Add Company API KEYS */
export const addIndividualLimit = (payload) => ({
    type: ADD_INDIVIDUAL_LIMIT,
    payload
});

export const addIndividualLimitSuccess = (payload) => ({
    type: ADD_INDIVIDUAL_LIMIT_SUCCESS,
    payload
});

export const addIndividualLimitFailure = (payload) => ({
    type: ADD_INDIVIDUAL_LIMIT_FAILURE,
    payload
});

