/*
* Reducer for market cap data
* */

import {
    GET_MARKET_CAP_DATA,
    GET_MARKET_CAP_DATA_SUCCESS,
    GET_MARKET_CAP_DATA_FAILURE
} from '../actions/types';

/* initial state */
const INITIAL_STATE = {
    Marketdata: null,
    loading: false,
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case GET_MARKET_CAP_DATA:
            return {...state, loading: true};
        case GET_MARKET_CAP_DATA_SUCCESS:
            return {...state, Marketdata: action.payload, loading: false};
        case GET_MARKET_CAP_DATA_FAILURE:
            return {...state, Marketdata: action.payload, loading: false};
        default:
            return state;
    }
};
