/*
* Forgot Password Actions
* */

import {FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_REQUEST_FAILURE, FORGOT_PASSWORD_REQUEST_SUCCESS,} from './types';

export const forgotPasswordRequest = (payload) => ({
    type: FORGOT_PASSWORD_REQUEST,
    payload,
});

export const forgotPasswordRequestSuccess = (payload) => ({
    type: FORGOT_PASSWORD_REQUEST_SUCCESS,
    payload,
});

export const forgotPasswordRequestFailure = (payload) => ({
    type: FORGOT_PASSWORD_REQUEST_FAILURE,
    payload,
});
