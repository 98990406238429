/*
* Saga file For MYUNIAML
* */

import {all, put, takeEvery} from 'redux-saga/effects';
import axios from "axios";
import {toast} from "react-toastify";

import {
    SET_RULES,
    GET_RULES_MYUNIAML
} from "../actions/types";
import {
    getRulesMyUniamlSuccess,
    getRulesMyUniamlFail,
    getRulesMyUniaml
} from '../actions';
import {apiUrl} from "../config";

function* getMYUNIAMLRules() {
    try {
        const res = yield axios.get(`${apiUrl}/api/back-office/my-uniaml`);
        yield put(getRulesMyUniamlSuccess(res.data.data));
    } catch (e) {
        yield put(getRulesMyUniamlFail(e));
    }
}

function* setMYUNIAMLRules(actions) {
    try {
        const res = yield axios.post(`${apiUrl}/api/back-office/my-uniaml`, actions.payload);
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
        if (res.data.meta.status === 1) toast.success(res.data.meta.message);
        yield put(getRulesMyUniaml());
    } catch (e) {
        console.log(e);
    }
}

/*watch Get Rules MYUNIAML */
export function* watchGetRulesMYUNIAML() {
    yield takeEvery(GET_RULES_MYUNIAML, getMYUNIAMLRules)
}

/*watch SET  RULes MYUNIAML*/
export function* watchSETMYUNIAML() {
    yield takeEvery(SET_RULES, setMYUNIAMLRules)
}

export default function* rootSaga() {
    yield all([
        watchGetRulesMYUNIAML(),
        watchSETMYUNIAML()
    ])
}