/*
* Actions For MyUNIAML
* */

import {GET_RULES_MYUNIAML, GET_RULES_MYUNIAML_FAILURE, GET_RULES_MYUNIAML_SUCCESS, SET_RULES} from './types.js';

export const setRules = (payload) => ({
    type: SET_RULES,
    payload
});

export const getRulesMyUniaml = () => ({
    type: GET_RULES_MYUNIAML
});

export const getRulesMyUniamlSuccess = (payload) => ({
    type: GET_RULES_MYUNIAML_SUCCESS,
    payload
});

export const getRulesMyUniamlFail = (payload) => ({
    type: GET_RULES_MYUNIAML_FAILURE,
    payload
});