/*
* Reducer for sign up
* */

import {LOGIN_REQUEST, LOGIN_REQUEST_FAILURE, LOGIN_REQUEST_SUCCESS, SET_CURRENT_USER} from '../actions/types';

/* initial state */
const INITIAL_STATE = {
    data: null,
    loading: false,
    userDetails: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {...state, loading: true};
        case LOGIN_REQUEST_SUCCESS:
            return {...state, data: action.payload, loading: false};
        case LOGIN_REQUEST_FAILURE:
            return {...state, data: action.payload, loading: false};
        case SET_CURRENT_USER:
            return {...state, userDetails: action.payload};
        default:
            return state;
    }
};
