/*Component For Show Hide Password*/
import React from 'react';
import hidePassword from '../assets/img/backOffice/hide-password.svg'
import showPassword from '../assets/img/backOffice/show-password.svg'

export const ShowPasswordImage = ({data, toggle}) => {
    return (
        <>
            {data === false ?
                <img onClick={() => toggle(true)} style={{position: 'absolute', top: '13px', right: '10px'}}
                     src={showPassword} alt='showPassword'/> :
                <img onClick={() => toggle(false)} style={{position: 'absolute', top: '13px', right: '10px'}}
                     src={hidePassword} alt='hidePassword'/>
            }
        </>
    )
};
