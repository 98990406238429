/*
* Saga fire for scoring history
* */

import {all, put, takeLatest} from 'redux-saga/effects';
import axios from 'axios';

import {GET_SCORING_HISTORY} from '../actions/types';
import {getScoringHistorySuccess, getScoringHistoryFail} from '../actions';
import {apiUrl} from "../config";

let cancel;

function* getScoringHistoryAPI(actions) {
    const url = actions.payload !== undefined ? `${apiUrl}/api/get-scoring-result?page=${actions.payload.page}&search=${actions.payload.search}` : `${apiUrl}/api/get-scoring-result`;
    cancel && cancel();
    try {
        const res = yield axios.get(url, {
            cancelToken: new axios.CancelToken((c) => {
                cancel = c
            })
        });
        yield put(getScoringHistorySuccess(res.data));

    } catch (e) {
        yield put(getScoringHistoryFail(e))
    }
}

export function* watchGetScoringHistory() {
    yield takeLatest(GET_SCORING_HISTORY, getScoringHistoryAPI)


}

export default function* rootSaga() {
    yield all([
        watchGetScoringHistory()
    ])
}