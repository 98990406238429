import React, {Component} from 'react';
import {removeToken} from "../../config";

class ErrorBoundry extends Component {
    constructor() {
        super();
        this.state = {
            error: null,
            errorInfo: null,
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo,
        });
    }

    handleClick = () => {
        removeToken();
        window.location.href = '/signin'
    };

    render() {
        const {errorInfo, error} = this.state;
        const {children} = this.props;
        if (errorInfo) {
            return (
                <div className="text-white p-5">
                    <h2>Something went wrong.</h2>
                    <details style={{whiteSpace: 'pre-wrap'}}>
                        {error && error.toString()}
                        <br/>
                        {errorInfo.componentStack}
                    </details>
                    <button className="btn signup-btn mt-3 bg-orange text-white f-18 f-rub-med" onClick={this.handleClick}>Please Click this....</button>
                </div>
            );
        }
        return children;
    }
}

export default ErrorBoundry;
