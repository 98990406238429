import { GET_TOGGLE_STATUS } from "../actions/types";

const INIT_STATUS = {
    toggle: true
}

export default (state = INIT_STATUS, action) => {
    switch (action.type) {
        case GET_TOGGLE_STATUS:
            return { ...state, toggle: action.payload }
        default:
            return state;
    }
}