/*
* Action For sign in or signOUT
* */

import {LOGIN_REQUEST, LOGIN_REQUEST_FAILURE, LOGIN_REQUEST_SUCCESS, LOGOUT_REQUEST, SET_CURRENT_USER} from './types';

export const loginRequest = (payload) => ({
    type: LOGIN_REQUEST,
    payload,
});

export const loginRequestSuccess = (payload) => ({
    type: LOGIN_REQUEST_SUCCESS,
    payload,
});

export const loginRequestFail = (payload) => ({
    type: LOGIN_REQUEST_FAILURE,
    payload,
});

/*Action for LogOut User Request*/
export const logoutRequest = () => ({
    type: LOGOUT_REQUEST,
});

/*Action For Set Current User*/
export const setCurrentUser = (payload) => ({
    type: SET_CURRENT_USER,
    payload
});
