/*
* Saga for SignUp
* */

import {all, put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';

import {toast} from 'react-toastify';
import {REGISTER_REQUEST} from '../actions/types';
import {signupRequestSuccess, signupRequestFail, setCurrentUser, getAPIMode, getToggleStatus} from '../actions';
import {apiUrl, setToken, setAuthToken} from '../config';

function* signupRequest(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/sign-up`, action.payload);
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
        if (res.data.meta.status === 1) toast.success(res.data.meta.message);
        if (res.data.token) yield setToken(res.data.token);
        yield setAuthToken(res.data.token);
        if (res.data.token) {
            const user = yield axios.get(`${apiUrl}/api/login-user`);
            const toggle = yield axios.get(`${apiUrl}/api/toggle-status`);
            yield put(getToggleStatus(toggle.data.data.status));
            if(!toggle.data.data.status) yield put(getAPIMode('on'))
            yield put(setCurrentUser(user.data.data));
        }
        yield put(signupRequestSuccess(res));
    } catch (e) {
        yield put(signupRequestFail(e));
    }
}

/* watch function for sign up request */
export function* watchRegisterRequestAPI() {
    yield takeEvery(REGISTER_REQUEST, signupRequest);
}

export default function* rootSaga() {
    yield all([
        watchRegisterRequestAPI(),
    ]);
}
