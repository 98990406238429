export const url = 'http://173.249.54.3:8081/api/v1/chains';

export const token = 'Basic RTkxREQyN0VBODRDRjlCODE1Q0FFOUZCMjdEOEM0RjM1OEEyRDlBM0ZGNjNCNDEzNUUyMzMwQTBDMUVDNkJGODoyNjc3NUMyMzMwQzRCNkNDQTQ2QUIxQUIyNTA5MTg5QkYxNDE5QTUzMTc3NkZBMDcyNTA2NUMxODk0MTg0RDIz';

export const balanceChain = {
  BTC: '0849486e-a30a-222f-0a80-f015d63f2c31',
  ETH: '6cbea02d-00d9-4771-caaa-3a797d3cd68e',
  DASH: 'd6deca7c-bf6d-cf6e-2a08-dda48c86125b',
  LTC: 'e3be63fe-4a25-7082-49bf-cadab64d298c',
  BCH: '702d4ad0-599d-c56f-83cd-160733112e9d',
  XRP: 'c7523d6c-d15f-1775-8da7-da071bff638c',
};
