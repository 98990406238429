/*
* Reducer File For Monitoring
* */

import {
    GET_MONITORING_LIST,
    GET_MONITORING_LIST_SUCCESS,
    GET_MONITORING_LIST_FAILURE,
    SET_NOTIFICATION_ALERT,
    DELETE_MONITORING_RECORD,
    ADD_EDIT_MONITORING_USER,
    ADD_EDIT_MONITORING_USER_SUCCESS,
    ADD_EDIT_MONITORING_USER_FAILURE,
    GET_MONITORING_BY_ID,
    GET_MONITORING_BY_ID_SUCCESS,
    GET_MONITORING_BY_ID_FAILURE,
    ADD_MONITORING,
    DELETE_MONITOR_ADDRESS,
    ADD_MONITORING_ADDRESS,
    ADD_MONITORING_ALERT,
    REMOVE_SCORE_FROM_ADDRESS,
    GET_SCORE_FOR_MONITORING_ADDRESS,
    GET_SCORE_FOR_MONITORING_ADDRESS_SUCCESS,
    GET_SCORE_FOR_MONITORING_ADDRESS_FAILURE,
    SENT_SCORE_ADDRESS_PROVIDER,
    COMMENT_ADD_UPDATE_MONITORING,
    COMMENT_LIST_MONITORING,
    COMMENT_LIST_MONITORING_SUCCESS,
    COMMENT_LIST_MONITORING_FAILURE, DELETE_COMMENT_MONITORING
} from '../actions/types';

const INITIAL_STATE = {
    monitoringList: {},
    loading: false,
    userData: null,
    loadUserAddEdit: false,
    monitoringById: {},
    scoreForAddress: null,
    commentLoader: false,
    commentList: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_MONITORING_LIST:
            return {...state, loading: true};
        case GET_MONITORING_LIST_SUCCESS:
            return {...state, loading: false, monitoringList: action.payload};
        case GET_MONITORING_LIST_FAILURE:
            return {...state, loading: true, monitoringList: action.payload};
        case SET_NOTIFICATION_ALERT:
            return {...state, loading: true};
        case DELETE_MONITORING_RECORD:
            return {...state, loading: true};
        case ADD_EDIT_MONITORING_USER:
            return {...state, loadUserAddEdit: true};
        case ADD_EDIT_MONITORING_USER_SUCCESS:
            return {...state, userData: action.payload, loadUserAddEdit: false};
        case ADD_EDIT_MONITORING_USER_FAILURE:
            return {...state, userData: action.payload, loadUserAddEdit: false};
        case GET_MONITORING_BY_ID:
            return {...state, loading: true};
        case GET_MONITORING_BY_ID_SUCCESS:
            return {...state, monitoringById: action.payload, loading: false};
        case GET_MONITORING_BY_ID_FAILURE:
            return {...state, monitoringById: action.payload, loading: false};
        case ADD_MONITORING:
            return {...state, loading: true};
        case DELETE_MONITOR_ADDRESS:
            return {...state, loading: true};
        case ADD_MONITORING_ADDRESS:
            return {...state, loading: true};
        case ADD_MONITORING_ALERT:
            return {...state, loading: true};
        case REMOVE_SCORE_FROM_ADDRESS:
            return {...state, loading: true};
        case GET_SCORE_FOR_MONITORING_ADDRESS:
            return {...state, loading: true};
        case GET_SCORE_FOR_MONITORING_ADDRESS_SUCCESS:
            return {...state, scoreForAddress: action.payload, loading: false};
        case GET_SCORE_FOR_MONITORING_ADDRESS_FAILURE:
            return {...state, scoreForAddress: action.payload, loading: false};
        case SENT_SCORE_ADDRESS_PROVIDER:
            return {...state, loading: true};
        case COMMENT_ADD_UPDATE_MONITORING:
            return {...state, commentLoader: true};
        case COMMENT_LIST_MONITORING:
            return {...state, commentLoader: true};
        case COMMENT_LIST_MONITORING_SUCCESS:
            return {...state, commentLoader: false, commentList: action.payload};
        case COMMENT_LIST_MONITORING_FAILURE:
            return {...state, commentLoader: false, commentList: action.payload};
        case DELETE_COMMENT_MONITORING:
            return {...state, commentLoader: true};
        default:
            return state;
    }
}