/*
*  Reducer for back office admin
* */

import {
    GET_USERS,
    GET_USERS_FAILURE,
    GET_USERS_SUCCESS,
    ADD_USER,
    ADD_USER_FAILURE,
    ADD_USER_SUCCESS,
    DELETE_USER,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,

    GET_API_KEY,
    GET_API_KEY_FAILURE,
    GET_API_KEY_SUCCESS,
    ADD_API_KEY,
    ADD_API_KEY_FAILURE,
    ADD_API_KEY_SUCCESS,
    DELETE_API_KEY,
    DELETE_API_KEY_FAILURE,
    DELETE_API_KEY_SUCCESS
} from '../actions/types'


const INITIAL_STATE = {
    userData: {},
    dataLoading: false,
    addUserData: {},
    deleteUser: '',
    apiData: {},
    addApi: {},
    deleteApi: ''
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case GET_USERS:
            return {...state, dataLoading: true};
        case GET_USERS_SUCCESS:
            return {...state, userData: action.payload, dataLoading: false};
        case GET_USERS_FAILURE:
            return {...state, userData: action.payload, dataLoading: false};

        case ADD_USER:
            return {...state};
        case ADD_USER_SUCCESS:
            return {...state, addUserData: action.payload};
        case ADD_USER_FAILURE:
            return {...state, addUserData: action.payload};

        case  DELETE_USER:
            return {...state};
        case DELETE_USER_SUCCESS:
            return {...state, deleteUser: action.payload};
        case DELETE_USER_FAILURE:
            return {...state, deleteUser: action.payload};

        case GET_API_KEY:
            return {...state, dataLoading: true};
        case GET_API_KEY_SUCCESS:
            return {...state, apiData: action.payload, dataLoading: false};
        case GET_API_KEY_FAILURE:
            return {...state, apiData: action.payload, dataLoading: false};

        case ADD_API_KEY:
            return {...state};
        case ADD_API_KEY_SUCCESS:
            return {...state, addApi: action.payload};
        case ADD_API_KEY_FAILURE:
            return {...state, addApi: action.payload};

        case  DELETE_API_KEY:
            return {...state};
        case DELETE_API_KEY_SUCCESS:
            return {...state, deleteApi: action.payload};
        case DELETE_API_KEY_FAILURE:
            return {...state, deleteApi: action.payload};

        default:
            return state;
    }
}

