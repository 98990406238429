/*
* Saga File for Forgote Password
* */

import {all, put, takeEvery} from 'redux-saga/effects';
import axios from 'axios';
import {toast} from 'react-toastify';

import {FORGOT_PASSWORD_REQUEST} from '../actions/types';
import {forgotPasswordRequestSuccess, forgotPasswordRequestFailure} from '../actions';
import {apiUrl} from '../config/helper';

function* forgotPassRequest(action) {
    try {
        const res = yield axios.post(`${apiUrl}/api/forget-password`, action.payload);
        if (res.data.meta.status === 0) toast.error(res.data.meta.message);
        if (res.data.meta.status === 1) toast.success(res.data.meta.message);
        yield put(forgotPasswordRequestSuccess(res.data));
    } catch (e) {
        yield put(forgotPasswordRequestFailure(e));
    }
}

/* watch function for sign in request */
export function* watchforgotPassRequestAPI() {
    yield takeEvery(FORGOT_PASSWORD_REQUEST, forgotPassRequest);
}

export default function* rootSaga() {
    yield all([
        watchforgotPassRequestAPI(),
    ]);
}
