import {GET_CRYPTO_MARKET, GET_CRYPTO_MARKET_SUCCESS, GET_CRYPTO_MARKET_FAILURE} from '../actions/types'

const INIT_STATUS = {
  currentCryptoList: [],
  fetching: false,
  counter: 0,
  allCryptoList: []
}

export default (state = INIT_STATUS, action) => {
  switch (action.type) {
    case GET_CRYPTO_MARKET:
      return {...state, fetching: true}
    case GET_CRYPTO_MARKET_SUCCESS:
      return {
        ...state,
        currentCryptoList: action.payload.currentCryptoList,
        allCryptoList: action.payload.allCryptoList,
        fetching: false
      }
    case GET_CRYPTO_MARKET_FAILURE:
      return {
        ...state,
        currentCryptoList: action.payload,
        fetching: false
      }
    default:
      return state;
  }
}
