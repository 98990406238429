// Action file for scoring history

import {GET_SCORING_HISTORY, GET_SCORING_HISTORY_FAILURE, GET_SCORING_HISTORY_SUCCESS} from './types';

export const getScoringHistory = (payload) => ({
    type: GET_SCORING_HISTORY,
    payload
});

export const getScoringHistorySuccess = (payload) => ({
    type: GET_SCORING_HISTORY_SUCCESS,
    payload
});

export const getScoringHistoryFail = (payload) => ({
    type: GET_SCORING_HISTORY_FAILURE,
    payload
});