/*
* Action Super Admin Header
* */

import {GET_COUNT_SUPER_ADMIN, GET_COUNT_SUPER_ADMIN_FAILURE, GET_COUNT_SUPER_ADMIN_SUCCESS} from "./types";

export const getSuperAdminCount = () => ({
    type: GET_COUNT_SUPER_ADMIN,
});

export const getSuperAdminCountSuccess = (payload) => ({
    type: GET_COUNT_SUPER_ADMIN_SUCCESS,
    payload
});

export const getSuperAdminCountFail = (payload) => ({
    type: GET_COUNT_SUPER_ADMIN_FAILURE,
    payload
});