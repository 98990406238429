/**
 * Created By Sanjay
 * Index file of Saga
 */

import {all} from 'redux-saga/effects';

import SearchCoinSaga from './searchCoinSaga';
import CoinDetailsSaga from './coinDetailsSaga';
import RegisterRequest from './signUpSaga';
import LoginRequest from './signInSaga';
import ForgotPasswordSaga from './forgotPasswordSaga';
import AutoCompleteAddressSaga from './autoCompleteAddressSaga'
import MarketCapSaga from './marketCapSaga'
import superAdminHeaderSaga from "./superAdminHeaderSaga";
import SuperAdminSaga from "./superAdminSaga";
import MonitoringSaga from "./monitoringSaga";
import BackOfficeAdminSaga from "./backOfficeAdminSaga";
import BackofficeHeaderAdminSaga from "./backOfficeHeaderAdminSaga";
import MyUNIAMLSaga from './myUniamlSaga';
import ScoringHistorySaga from './scroingHistorySaga';
import NewsSaga from './newsSaga'
import CryptoMarketSaga from './cryptoMarketSaga'

export default function* rootSaga() {
  yield all([
    SearchCoinSaga(),
    CoinDetailsSaga(),
    RegisterRequest(),
    LoginRequest(),
    ForgotPasswordSaga(),
    AutoCompleteAddressSaga(),
    MarketCapSaga(),
    superAdminHeaderSaga(),
    SuperAdminSaga(),
    MonitoringSaga(),
    BackOfficeAdminSaga(),
    BackofficeHeaderAdminSaga(),
    MyUNIAMLSaga(),
    ScoringHistorySaga(),
    NewsSaga(),
    CryptoMarketSaga()
  ]);
}
